export const dateParserShort = (num) => {
    let options = {
        // hour: "2-digit", minute: "2-digit", second: "2-digit", weekday: "long",
        year: "numeric", month: "short", day: "numeric"
    };

    let timestamp = Date.parse(num);
    let date = new Date(timestamp).toLocaleDateString('fr-FR', options);

    return date.toString();
};

export const dateParserMiddle = (num) => {
    let options = {
        hour: "2-digit", minute: "2-digit",
        year: "numeric", month: "short", day: "numeric"
    };

    let timestamp = Date.parse(num);
    let date = new Date(timestamp).toLocaleDateString('fr-FR', options);

    return date.toString();
};


export const dateParserLong = (num) => {
    let options = {
        hour: "2-digit", minute: "2-digit", second: "2-digit", weekday: "long",
        year: "numeric", month: "short", day: "numeric"
    };

    let timestamp = Date.parse(num);
    let date = new Date(timestamp).toLocaleDateString('fr-FR', options);

    return date.toString();
};

export const calculateAge = (birthday) => {
    var ageDifMs = Date.now() - new Date(birthday).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const calculateImc = (weigth, size) => {
    try {
        return +weigth / (+size / 1000 * +size / 1000).toFixed(2);
    } catch (error) {
        console.log(error)
        return '--'
    }
}

export function reduceByDate(obj, prop) {

    return obj.reduce(function (acc, item) {
        let key = dateParserMiddle(item[prop])
        if (!acc[key]) {
            acc[key] = []
        }

        acc[key].push(item)
        return acc
    }, {})

}

