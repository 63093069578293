import React, { useEffect, useState } from 'react'
import AdminTable from './shared/table/AdminTable'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewProfessional from './modals/NewProfessional';
import { useDispatch, useSelector } from 'react-redux';
import Nofications from './shared/Nofications';
import { Button } from '@mui/material';

const Admins = () => {
    const [open, setOpen] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);

    const { user } = useSelector(state => state.auth);
    const { success, error } = useSelector(state => state.professionals);

    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseNotif = () => {
        setOpenNotif(false);
        dispatch({ type: 'CLEAN_PROFESSIONAL_MESSAGES' });
    };

    useEffect(() => {
        if (success || error) {
            setOpenNotif(true)
        }
    }, [success, error]);

    return (
        <div>
            <div className="listContainer">
                <div className="traderTitle">

                    <span className='standardTitle'>Liste des médecins</span>
                    {user?.role === 'Administrator' &&
                        <Button onClick={() => setOpen(true)} endIcon={<AddCircleIcon />}
                            variant="outlined" color='success'
                        >
                            Nouveau professionel
                        </Button>
                    }
                </div>
                <AdminTable />
            </div>

            <NewProfessional
                open={open}
                handleClose={handleClose}
            />

            <Nofications
                open={openNotif}
                handleClose={handleCloseNotif}
                transition="TransitionLeft"
                message={
                    success ? 'Dossier médical créé avec succés' :
                        error ? "Erreur lors de l'ajout d'un dossier médical" :
                            "Erreur technique lors de la création d'un dossier médical"
                }
                severity={success ? 'success' : 'error'}
            />
        </div>
    )
}

export default Admins