import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { dateParserShort } from '../../utils/functions';
import { Box } from '@mui/system';
import { Button, FormControl, Stack, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { updateProfessional } from '../../redux/actions/appActions';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfessionalDetails({ open, handleClose, professional, setProfessional }) {
    // const [professional, setProfessional] = React.useState(prof);

    const [firstName, setFirstName] = React.useState(professional?.firstName);
    const [lastName, setLastName] = React.useState(professional?.lastName);
    const [birthday, setBirthday] = React.useState(professional?.birthday);
    const [city, setCity] = React.useState(professional?.city);
    const [address, setAddress] = React.useState(professional?.address);
    const [phone, setPhone] = React.useState(professional?.phone);
    const [email, setEmail] = React.useState(professional?.email);
    const [jobTitle, setJobTitle] = React.useState(professional?.jobTitle);

    const [editMode, setEditMode] = React.useState(false);

    const { updatedProfessional, } = useSelector(state => state.professionals);

    const dispatch = useDispatch();

    const handleUpdate = (e) => {
        e.preventDefault();
        dispatch(updateProfessional(professional?.id, { firstName, lastName, phone, birthday, jobTitle, city, email, address }));
        setEditMode(false)
    }

    const handleUpdateAccountStatus = () => {
        const newStatus = professional?.status === "Actif" ? "Inactif" : "Actif";
        dispatch(updateProfessional(professional?.id, { status: newStatus }));
        setEditMode(false)
    }

    const handleBirthdayChange = (newValue) => {
        setBirthday(newValue);
    };

    React.useEffect(() => {
        if (updatedProfessional && updatedProfessional?.id) {
            setProfessional(updatedProfessional);
        }
    }, [updatedProfessional, setProfessional])

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#e6e6e6', color: 'black' }}>
                <Toolbar>
                    {professional?.status === 'Actif' ? <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase', color: 'green', }} variant="body2" component="div">
                        Compte ouvert
                    </Typography> :
                        <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase', color: 'orangered', }} variant="body2" component="div">
                            Compte fermé
                        </Typography>
                    }
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box m={1} display="flex" justifyContent="space-between">
                {professional?.status === 'Actif' ?
                    <>
                        {!editMode && <Button variant="contained" endIcon={<EditIcon />} onClick={() => setEditMode(true)} sx={{ backgroundColor: 'black' }}>
                            Modifier les informations
                        </Button>
                        }
                    </> :
                    <Button variant="outlined" sx={{ borderColor: 'black', color: 'black' }}
                        endIcon={<PlayCircleOutlineIcon color='success' />} onClick={handleUpdateAccountStatus}>
                        Activer ce compte
                    </Button>
                }

            </Box>

            <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginLeft: 1, width: "99%", backgroundColor: "#595959" }}>
                <Typography sx={{ paddingBottom: 2, color: "white", paddingTop: 2, textTransform: 'uppercase' }} variant="body2" component="div">
                    Informations détaillées de profil
                </Typography>
            </Box>

            <List sx={{ height: "minContent" }}>
                <ListItem >

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="firstName"
                                    label="Nom"
                                    name="Nom"
                                    autoComplete="firstName"
                                    autoFocus
                                    defaultValue={professional?.firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }}
                                primary="NOM COMPLET" secondary={professional?.firstName + ' ' + professional?.lastName} />
                    }
                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="lastName"
                                    label="Prénom"
                                    name="lastName"
                                    autoComplete="lastName"
                                    autoFocus
                                    defaultValue={professional?.lastName}
                                    onChange={e => setLastName(e.target.value)}
                                />
                            </ListItemText> :
                            ""
                    }


                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <FormControl variant="standard" sx={{ mt: 1, }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="Date de naissance"
                                                inputFormat="MM/DD/YYYY"
                                                value={birthday}
                                                onChange={handleBirthdayChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </FormControl>
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="DATE DE NAISSANCE" secondary={professional?.birthday ? dateParserShort(professional?.birthday) : "--"} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="jobTitle"
                                    label="Poste"
                                    name="jobTitle"
                                    autoComplete="jobTitle"
                                    autoFocus
                                    defaultValue={professional?.jobTitle !== '' ? professional?.jobTitle : 'NA'}
                                    onChange={e => setJobTitle(e.target.value)}

                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="Poste" secondary={professional?.jobTitle ? professional?.jobTitle : '--'} />
                    }

                    {
                        !editMode &&
                        <ListItemText sx={{ width: "20%" }} primary="VILLE DE NAISSANCE" secondary={professional?.city ? professional?.city : '--'} />
                    }
                </ListItem>
                <Divider />

                <ListItem >
                    {
                        editMode &&
                        <ListItemText sx={{ width: "20%" }} >
                            <TextField margin="normal"
                                required
                                fullWidth={false}
                                id="city"
                                label="Ville"
                                name="city"
                                autoComplete="city"
                                autoFocus
                                defaultValue={professional?.city !== '' ? professional?.city : 'NA'}
                                onChange={e => setCity(e.target.value)}
                            />
                        </ListItemText>
                    }
                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="phone"
                                    label="Téléphone"
                                    name="phone"
                                    autoComplete="phone"
                                    autoFocus
                                    defaultValue={professional?.phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="TÉLÉPHONE" secondary={professional?.phone ? professional?.phone : '--'} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="email"
                                    label="EMAIL"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    defaultValue={professional?.email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="EMAIL" secondary={professional?.email ? professional?.email : '--'} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="address"
                                    label="Adresse"
                                    name="address"
                                    autoComplete="address"
                                    autoFocus
                                    defaultValue={professional?.address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="ADRESSE" secondary={professional?.address ? professional?.address : '--'} />
                    }

                    {!editMode && <ListItemText sx={{ width: "20%" }} primary="STATU" secondary={professional?.status ? professional?.status : '--'} />}
                </ListItem>

                <Divider />


                <Divider />
            </List>
            <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                <Box display="flex" justifyContent="center" >
                    <Stack direction="row" spacing={2}>
                        {editMode &&
                            <Stack direction="row" spacing={50}>
                                <Stack direction="row" spacing={3}>
                                    <Button variant="outlined" endIcon={<ClearIcon />} onClick={() => setEditMode(false)} sx={{ borderColor: 'black', color: 'black' }}>
                                        Annuler les modifications
                                    </Button>
                                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleUpdate} sx={{ backgroundColor: 'black' }}>
                                        Valider les modifications
                                    </Button>
                                </Stack>

                                {professional?.status === 'Actif' &&
                                    <Button variant="outlined" color="error" endIcon={<StopCircleIcon />} onClick={handleUpdateAccountStatus}>
                                        Désactiver ce compte
                                    </Button>
                                }
                            </Stack>
                        }
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    );
}