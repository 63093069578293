import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { createProfessional, findFoldersByProfessional } from '../../redux/actions/appActions';
import { Checkbox, FormControlLabel } from '@mui/material';

export default function NewProfessional({ open, handleClose }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState(dayjs(new Date()));
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [contractType, setContractType] = useState('');
  const [patronym, setPatronym] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const { user } = useSelector(state => state.auth);

  const handleBirthdayChange = (newValue) => {
    setBirthday(newValue);
  };

  const handleChangeContractType = (event) => {
    setContractType(event.target.value);
  };

  const handleChangePatronym = (event) => {
    setPatronym(event.target.value);
  }

  const handleChangeIsAdmin = (event) => {
    setIsAdmin(event.target.checked);
  };
  const dispatch = useDispatch();

  const handleRegister = (e) => {
    e.preventDefault();
    if (user && user.clinic !== '' && user.clinic !== null && user.clinic !== 'undefine') {
      dispatch(createProfessional({
        firstName,
        lastName,
        phone,
        birthday,
        email,
        address,
        city,
        jobTitle,
        contractType,
        clinic: user.clinic,
        patronym,
        password: "password",
        role: isAdmin ? "Administrator" : "Professional"
      }));
      dispatch(findFoldersByProfessional(user.id))
    }

    handleClose();

  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>AJOUT D'UN NOUVEAU PROFESSIONEL</DialogTitle>
      <DialogContent>
        <form onSubmit={handleRegister}>
          <FormControlLabel
            label="Cochez si vous lui donner les droits administrateur"
            control={<Checkbox checked={isAdmin} onChange={handleChangeIsAdmin} />
            }
          />
          <FormControl sx={{ mt: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">Patronyme</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={patronym}
              onChange={handleChangePatronym}
              label="Patronyme"
              sx={{ width: "100%" }}
            >
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value={"Docteur"}>Docteur</MenuItem>
              <MenuItem value={"Mlle"}>Mlle</MenuItem>
              <MenuItem value={"Mme"}>Mme</MenuItem>
              <MenuItem value={"Mr"}>Mr</MenuItem>
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="firstName"
            label="Prénom"
            type="text"
            fullWidth
            onChange={e => setFirstName(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="lastName"
            label="Nom"
            type="text"
            fullWidth
            onChange={e => setLastName(e.target.value)}
            required
          />

          <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Date de naissance"
                  inputFormat="MM/DD/YYYY"
                  value={birthday}
                  onChange={handleBirthdayChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="vity"
            label="Ville de naisssance"
            type="text"
            fullWidth
            onChange={e => setCity(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="Téléphone"
            type="text"
            fullWidth
            onChange={e => setPhone(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            onChange={e => setEmail(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="Adresse"
            type="text"
            fullWidth
            onChange={e => setAddress(e.target.value)}

          />

          <TextField
            autoFocus
            margin="dense"
            id="jobTitile"
            label="Poste occupé"
            type="jobTitile"
            fullWidth
            onChange={e => setJobTitle(e.target.value)}
            required
          />

          <FormControl sx={{ mt: 1, mb: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">Type de contrat</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={contractType}
              onChange={handleChangeContractType}
              label="Type de contrat"
              sx={{ width: "100%" }}
            >
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value={"CDD"}>CDD</MenuItem>
              <MenuItem value={"CDI"}>CDI</MenuItem>
              <MenuItem value={"STAGE"}>STAGE</MenuItem>
              <MenuItem value={"ALTERNANCE"}>ALTERNANCE</MenuItem>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button type='submit' onClick={handleRegister}>Valider</Button>
      </DialogActions>
    </Dialog>
  );
}