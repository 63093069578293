import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/admin/Home';
import Login from './pages/Login';
import { AdminRoute } from './ProtectedRoutes/AdminRoute';
import { getToken } from './redux/actions/authActions';
import TimeAgo from 'javascript-time-ago'

import fr from 'javascript-time-ago/locale/fr.json'

TimeAgo.addDefaultLocale(fr)

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);


  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path='/' element={<AdminRoute><Home /></AdminRoute>} />
          <Route path='/login' element={<Login />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
