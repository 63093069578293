import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux';
import { createBill, findBillsByClinic, findFoldersByClinic, findFoldersByProfessional, searchFoldersByCriteria } from '../../redux/actions/appActions';
import { useEffect } from 'react';
import { AppBar, Box, FormControl, IconButton, InputAdornment, InputBase, InputLabel, MenuItem, Select, Stack, Toolbar, Typography } from '@mui/material';
import { dateParserShort } from '../../utils/functions';
import SendIcon from '@mui/icons-material/Send';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import 'dayjs/locale/fr';

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CloseIcon from '@mui/icons-material/Close';
import BillingDetails from '../shared/BillingDetails';
import { AccountCircle } from '@mui/icons-material';


export default function NewBilling({ open, handleClose }) {
  const [isSearched, setIsSearched] = useState(false);
  const [criteria, setCriteria] = useState("");
  const [openNewBillingForm, setOpenNewBillingForm] = useState(false);

  const [description, setDescription] = useState();
  const [amount, setAmount] = useState();
  const [tax, setTax] = useState();
  const [invoices, setInvoices] = useState([]);

  const [selectedFolder, setSelectedFolder] = React.useState(null);
  const { clinical } = useSelector(state => state.clinics);

  const { user } = useSelector(state => state.auth);

  const { folders, folder } = useSelector(state => state.folders);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    let sum = 0;
    invoices?.map(inv => {
      sum = sum + parseFloat(inv.amount)

      if (invoices[0]?.tax && parseFloat(invoices[0]?.tax) > 0) {
        let taxCompute = parseFloat(invoices[0]?.tax) / 100
        setTotal(sum - (sum * taxCompute));
      } else {
        setTotal(sum);
      }


    })
  }, [invoices]);

  const addToInvoicesList = () => {
    if (description !== "" && amount) {
      setInvoices([...invoices, { description, amount, tax }])
      setOpenNewBillingForm(false);
      setDescription("");
      setAmount(null);
      setTax(null)
    }
  }


  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(findFoldersByClinic(user?.clinic));
  }, [dispatch, user])


  useEffect(() => {
    if (folder) setSelectedFolder(folder);
  }, [folder])

  const handleBillRegister = (e) => {
    e.preventDefault();
    if (user && user.clinic !== '' && user.clinic !== null && user.clinic !== 'undefine') {
      dispatch(createBill({
        owner: selectedFolder?.patientFirstname + " " + selectedFolder?.patientLastname,
        amount: total,
        tax: invoices[0]?.tax,
        folder: selectedFolder.id,
        clinic: clinical?.id,
        invoices,
      }));

      dispatch(findBillsByClinic(clinical?.id));
    }

    handleClose();
  }

  const close = () => {
    handleClose();
  }

  const handleChangeFolder = (event) => {
    setSelectedFolder(event.target.value);
    console.log(event.target.value)
  };

  const handleSearch = () => {
    if (criteria) {
      dispatch(searchFoldersByCriteria(criteria, user?.clinic));
      setIsSearched(true);
    }
  }

  const handleResetSearch = () => {
    setIsSearched(false);
    setSelectedFolder(null);
    if (user?.role === "Medical secretary") {
      dispatch(findFoldersByClinic(user?.clinic));
    } else {
      dispatch(findFoldersByProfessional(user?.id));
    }
    setCriteria('');
  }


  useEffect(() => {
    if (criteria !== '' && criteria.length > 2) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria]);


  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <AppBar sx={{ position: 'relative', backgroundColor: '#e6e6e6', color: 'black' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase', }} variant="body2" component="div">
            Espace facturation
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        {!selectedFolder ?
          <Box display="flex" alignItems="center" justifyContent="space-evenly">
            <FormControl sx={{ mt: 1, width: "400px" }}>
              <InputLabel id="demo-simple-select-standard-label">PATIENT</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={folder}
                onChange={handleChangeFolder}
                label="Type de consultation"
                sx={{ width: "100%" }}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem >
                {folders && folders.map(fold => (
                  <MenuItem
                    key={fold.id}
                    value={fold}
                    sx={{ fontSize: "14px" }}
                  >
                    {fold?.patientFirstname + ' ' + fold?.patientLastname + ' - Né(e) le : ' + dateParserShort(fold?.patientBirthday) + ' - Tel: ' + fold?.patientPhone + ' - N° Dossier: ' + (fold?.number ? fold?.number : fold?.id)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display="flex" borderRadius="3px" width="400px"
              sx={{ padding: 1, borderStyle: "solid", borderColor: 'gray', borderWidth: '1px' }}
            >
              <InputBase
                sx={{ ml: 2, flex: 1 }}
                placeholder="N° dossier, téléphone, nom ou prénom"
                onChange={event => setCriteria(event.target.value)}
                value={criteria}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              {
                isSearched ?
                  <IconButton type='button' sx={{ p: 1 }} onClick={handleResetSearch}>
                    <CloseIcon />
                  </IconButton>
                  :
                  <IconButton type='button' sx={{ p: 1 }} onClick={handleSearch} >
                    <SearchIcon />
                  </IconButton>
              }
            </Box>

          </Box>

          : <Box display="flex" alignItems="center" justifyContent="space-between"
            sx={{ borderBottom: 1, borderBottomColor: 'silver', borderBottomStyle: "solid", paddingBottom: 2 }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleResetSearch}
              aria-label="close"
            >
              <CancelPresentationIcon />
            </IconButton>

            {!openNewBillingForm ?
              <Button color='success' variant="outlined" endIcon={<AddCircleIcon />} onClick={() => setOpenNewBillingForm(true)}>
                Ajouter une nouvelle ligne à la facture
              </Button>
              : // ajout d'une nuvelle ligne de facture
              <Box display="flex" alignItems="center" justifyContent="space-between" width="70%" >
                <Box flex={2} m={2}>
                  <TextField
                    id="billingName"
                    label="Intitulé de l'action à facturer"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}

                  />
                </Box>
                <Box flex={1} m={2}>
                  <TextField
                    id="billingName"
                    label="Montant"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}

                  />
                </Box>
                <Box flex={1} m={2}>
                  <TextField
                    id="tax"
                    label="Taxe"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                    value={tax}
                    onChange={(event) => setTax(event.target.value)}
                  />
                </Box>
                <Box flex={2} m={2} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Stack direction="row" spacing={2} mt={2} mr={2}>
                    <Button variant="outlined" endIcon={<SendIcon />} color="success" type='buton' onClick={addToInvoicesList} >
                      Ajouter
                    </Button>
                  </Stack>

                  <Stack direction="row" spacing={2} mt={2} ml={2}>
                    <Button variant="outlined" endIcon={<SendIcon />} color="error" type='buton' onClick={() => setOpenNewBillingForm(false)} >
                      Annuler
                    </Button>
                  </Stack>
                </Box>
              </Box>

            }
          </Box>
        }

        {
          clinical && selectedFolder &&
          <BillingDetails
            clinical={clinical}
            selectedFolder={selectedFolder}
            criteria={criteria}
            folders={folders}
            invoices={invoices}
            setSelectedFolder={setSelectedFolder}
          />
        }


      </DialogContent>
      <DialogActions>
        <Button onClick={close} color='error' >Annuler</Button>
        <Button type='submit' variant='outlined' color='success' onClick={handleBillRegister}>Valider la facture mainteant</Button>
      </DialogActions>
    </Dialog >
  );
}