import React from 'react'
import './chart.css'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const data = [
    {
        name: 'Janvier',
        Total: 4000,
    },
    {
        name: 'Février',
        Total: 3000,
    },
    {
        name: 'Mars',
        Total: 2000,
    },
    {
        name: 'Avril',
        Total: 2780,
    },
    {
        name: 'Avril',
        Total: 1890,
    },
    {
        name: 'Juin',
        Total: 2390,
    },
];

const Chart = ({ aspect, title }) => {
    return (
        <div className='chart'>
            <div className="title">{title}</div>
            <ResponsiveContainer width="100%" aspect={aspect}>
                <AreaChart width={730} height={250} data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" stroke='grey' />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" className='chartGrid' />
                    <Tooltip />
                    <Area type="monotone" dataKey="Total" stroke="#8884d8" fillOpacity={1} fill="url(#total)" />

                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Chart