import React, { useEffect, useState } from 'react'
import Widget from '../shared/widget/Widget'
import Chart from '../shared/chart/Chart'
import Featured from '../shared/featured/Featured'
import './dashboard.css'
import ChangePassword from '../modals/ChangePassword'
import { useDispatch, useSelector } from 'react-redux'
import { countTotalFoldersByClinical, countTotalFoldersByProfessional } from '../../redux/actions/statsActions'
import { findClinicalById } from '../../redux/actions/appActions'

const Dashboard = () => {
    const [open, setOpen] = useState(false);

    const { user } = useSelector(state => state.auth);
    const { totalActifs, totalArchives } = useSelector(state => state.stats);

    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.role === "Medical secretary") {
            dispatch(countTotalFoldersByClinical(user?.clinic));
        } else if (user?.role === "Medical secretary") {
            console.log('stats plateforme')
        } else {
            dispatch(countTotalFoldersByProfessional(user?.id));
        }
    }, [user, dispatch])

    useEffect(() => {
        if (user && user?.clinic) {
            dispatch(findClinicalById(user?.clinic));
        }
    }, [user, dispatch])


    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!user?.defaultPasswordHasChanged) setOpen(true);
    }, [user])

    return (
        <div className="dashboardContainer">
            <span className="dashboardTop">
                <span>TABLEAU DE BORD DE L'ÉTABLISSEMENT {user?.clinic.name}</span>
            </span>

            <hr />
            <div className="widgets">
                <Widget className="widget-card" title={"Nb dossiers ouverts"} count={totalActifs ? totalActifs : 0} />
                <Widget className="widget-card" title={"Nb dossiers archivés"} count={totalArchives ? totalArchives : 0} />
                {/* <Widget className="widget-card" title={"Nb RDV de la semaine"} count={9000} />
                <Widget className="widget-card" title={"Nb sconsulations du mois"} count={23000} /> */}
            </div>
            <div className="charts">
                <Featured balance={1200} />
                <Chart aspect={2 / 1} title="Nombre de consultations (6 derniers mois)" />
            </div>
            <ChangePassword
                open={open}
                handleClose={handleClose}
            />
        </div>
    )
}

export default Dashboard