import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useRef } from 'react'
import { useReactToPrint } from "react-to-print";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import banner from '../../assets/images/banner1.png';
import title from '../../assets/images/title1.png';
import ReactQuill from 'react-quill';
import { calculateAge, dateParserShort } from '../../utils/functions';

const PrintToPDF = ({ sheet, folder }) => {
    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'ordonnance',
    });


    return (
        <Box display="flex" flexDirection="column" sx={{ height: "minContent" }} >
            <Box display="flex" width="100vw" justifyContent="space-between" mb={2}>
                <Box width="100%" display="flex" justifyContent="center">
                    <Button variant="outlined" sx={{ borderColor: 'black', color: 'black' }} endIcon={<LocalPrintshopIcon />} onClick={handlePrint}>
                        Télécharger l'ordonnance
                    </Button>
                </Box>
            </Box>
            <Divider />

            <Box width="100%" display="flex" justifyContent="center" >
                <Box ref={componentRef} display="flex" flexDirection="column" justifyContent="center" p={4} alignItems="center" width="600px" >
                    <Box>
                        <img src={banner} alt="banner" />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" width="600px">
                        <Typography variant='body2'>Cotonou, le.. {dateParserShort(new Date())}</Typography>
                    </Box>
                    <Box>
                        <img src={title} alt="title" />
                    </Box>

                    <Box display="flex" flexDirection="column" justifyContent="flex-start" width="600px" alignItems="center">
                        <Box display="flex" justifyContent="space-between" width="600px" alignItems="flex-start">
                            <Typography variant='body2'>Nom:.....  {folder?.patientFirstname + ' ' + folder?.patientLastname}</Typography>
                            <Typography variant='body2'> Dossier N°:..... {folder?.number ? folder?.number : folder?.id} </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" width="600px" alignItems="flex-start">
                            <Typography variant='body2'> Âge:.. {calculateAge(folder?.patientBirthday)}</Typography>
                            <Typography variant='body2'> Poids:.. {sheet?.weight} </Typography>
                            <Typography variant='body2'> Taille:.. {sheet?.size} </Typography>
                            <Typography variant='body2'> Température:.. {sheet?.temperature} </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" width="600px">
                        <ReactQuill
                            value={sheet?.prescription}
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </Box>
                    <Box>

                    </Box>
                </Box>
            </Box>

            {/* <Box width="100%" display="flex" justifyContent="center" >
                <Box ref={componentRef} display="flex" flexDirection="column" justifyContent="center" p={4} alignItems="center" width="600px" >
                    <Box>
                        <img src={banner} alt="banner" />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" width="600px">
                        <Typography variant='body2'>Nouakchott, le.. {dateParserShort(new Date())} ..نواكشوط </Typography>
                    </Box>
                    <Box>
                        <img src={title} alt="title" />
                    </Box>
                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" width="600px">
                        <ReactQuill
                            value={sheet?.prescription}
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" width="600px" alignItems="center">
                        <Box display="flex" justifyContent="space-between" width="600px" alignItems="flex-start">
                            <Typography variant='body2'>Nom:.....  {folder?.patientFirstname + ' ' + folder?.patientLastname}  ......اسم ,</Typography>
                            <Typography variant='body2'> Dossier N°:..... {folder?.number ? folder?.number : folder?.id}  ......رقم الملف </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" width="600px" alignItems="flex-start">
                            <Typography variant='body2'> Âge:.. {calculateAge(folder?.patientBirthday)}  ..عمر</Typography>
                            <Typography variant='body2'> Poids:.. {sheet?.weight} ..الوزن</Typography>
                            <Typography variant='body2'> Taille:.. {sheet?.size} ..مقاس</Typography>
                            <Typography variant='body2'> Température:.. {sheet?.temperature} ..درجة حرارة</Typography>
                        </Box>
                    </Box>
                    <Box>

                    </Box>
                </Box>
            </Box> */}
        </Box >
    )
}

export default PrintToPDF