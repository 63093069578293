import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { createFolder, findFoldersByProfessional, findProfessionalsByClinic } from '../../redux/actions/appActions';

export default function NewFolder({ open, handleClose, role }) {
  const [patientFirstname, setPatientFirstname] = useState('');
  const [patientLastname, setPatientLastname] = useState('');
  const [patientBirthday, setPatientBirthday] = useState(dayjs(new Date()));
  const [patientCity, setPatientCity] = useState('');
  const [patientPhone, setPatientPhone] = useState('');
  const [patientEmail, setPatientEmail] = useState('');
  const [consulationType, setConsulationType] = React.useState('');
  const [patientGender, setPatientGender] = useState('');
  const [patientBloodGroup, setPatientBloodGroup] = useState('');
  const [pro, setPro] = useState('');

  const [comment, setComment] = React.useState('');

  const { user } = useSelector(state => state.auth);
  const { professionals } = useSelector(state => state.professionals);

  const handlePatientBirthdayChange = (newValue) => {
    setPatientBirthday(newValue);
  };

  const handleChangeConsulationType = (event) => {
    setConsulationType(event.target.value);
  };

  const handleChangePatientBloodGroup = (event) => {
    setPatientBloodGroup(event.target.value);
  };

  const handleChangePatientGender = (event) => {
    setPatientGender(event.target.value);
  };

  const handleChangePro = (event) => {
    setPro(event.target.value);
  };

  const dispatch = useDispatch();

  const handleRegister = (e) => {
    e.preventDefault();
    if (user && user.clinic !== '' && user.clinic !== null && user.clinic !== 'undefine') {
      dispatch(createFolder({
        patientFirstname,
        patientLastname,
        patientPhone,
        patientBirthday,
        patientEmail,
        patientGender,
        patientCity,
        consulationType,
        comment,
        clinic: user.clinic,
        professional: user?.role === "Medical secretary" ? pro : user.id,
        patientBloodGroup,
      }));
      dispatch(findFoldersByProfessional(user.id))
    }

    handleClose();

  }

  useEffect(() => {
    if (user?.role === 'Medical secretary') {
      dispatch(findProfessionalsByClinic(user?.clinic));
    }
  }, [dispatch, user])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>CRÉATION D'UN NOUVEAU DOSSIER MÉDICAL</DialogTitle>
      <DialogContent>
        <form onSubmit={handleRegister}>

          {user?.role === "Medical secretary" &&
            <FormControl sx={{ mt: 1, width: "100%", paddingRight: 2 }}>
              <InputLabel id="demo-simple-select-standard-label">Médecin traitant le dossier</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={pro}
                onChange={handleChangePro}
                label="Type de consultation"
                sx={{ width: "100%" }}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {
                  professionals && professionals.map(prof => (
                    <MenuItem value={prof.id}>{'Dr ' + prof?.firstName + ' ' + prof?.lastName}</MenuItem>
                  ))
                }

              </Select>
            </FormControl>}

          <FormControl sx={{ mt: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">Sexe du patient</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={patientGender}
              onChange={handleChangePatientGender}
              label="Type de consultation"
              sx={{ width: "100%" }}
            >
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value={"Féminin"}>Féminin</MenuItem>
              <MenuItem value={"Masculin"}>Masculin</MenuItem>
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="patientFirstname"
            label="Prénom du patient"
            type="text"
            fullWidth
            onChange={e => setPatientFirstname(e.target.value)}
          />

          <TextField
            autoFocus
            margin="dense"
            id="patientLastName"
            label="Nom du patient"
            type="text"
            fullWidth
            onChange={e => setPatientLastname(e.target.value)}
          />

          <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Date de naissance du patient"
                  inputFormat="MM/DD/YYYY"
                  value={patientBirthday}
                  onChange={handlePatientBirthdayChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="patientCity"
            label="Ville de naisssance du patient"
            type="text"
            fullWidth
            onChange={e => setPatientCity(e.target.value)}
          />

          <TextField
            autoFocus
            margin="dense"
            id="patientPhone"
            label="Téléphone du patient"
            type="text"
            fullWidth
            onChange={e => setPatientPhone(e.target.value)}
          />

          <TextField
            autoFocus
            margin="dense"
            id="patientEmail"
            label="Email du patient"
            type="email"
            fullWidth
            onChange={e => setPatientEmail(e.target.value)}
          />

          <FormControl sx={{ mt: 1, mb: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">Groupe sanguin du patient</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={patientBloodGroup}
              onChange={handleChangePatientBloodGroup}
              label="Groupe sanguin du patient"
              sx={{ width: "100%" }}
            >
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value={"A+"}>A+</MenuItem>
              <MenuItem value={"A-"}>A-</MenuItem>
              <MenuItem value={"B+"}>B+</MenuItem>
              <MenuItem value={"B-"}>B-</MenuItem>
              <MenuItem value={"AB+"}>AB+</MenuItem>
              <MenuItem value={"AB-"}>AB-</MenuItem>
              <MenuItem value={"O+"}>O+</MenuItem>
              <MenuItem value={"O-"}>O-</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">Type de consultation</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={consulationType}
              onChange={handleChangeConsulationType}
              label="Type de consultation"
              sx={{ width: "100%" }}
            >
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value={"Pédiatrie"}>Pédiatrie</MenuItem>
              <MenuItem value={"Churérgie"}>Gynécologie</MenuItem>
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Note"
            type="text"
            fullWidth
            multiline
            rows={5}
            onChange={e => setComment(e.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button type='submit' onClick={handleRegister}>Valider</Button>
      </DialogActions>
    </Dialog>
  );
}