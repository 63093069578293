import React, { useEffect } from 'react'
import './customTable.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { findAllClinics, } from '../../../redux/actions/appActions';
import ClinicDetails from '../../modals/ClinicDetails';
import { Button } from '@mui/material';


const ClinicsTable = () => {
    const [open, setOpen] = React.useState(false);
    const { clinics } = useSelector(state => state.clinics);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(findAllClinics());
    }, [dispatch])


    const handleClose = () => {
        setOpen(false);
    };

    const handleDisplayClinicInfo = (clinic) => {
        setOpen(true)
        dispatch({ type: "SELECTED_CLINIC", payload: clinic });
    }

    return <>
        <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="tableCell">CLINIQUE</TableCell>
                        <TableCell className="tableCell">SPÉCIALITÉ</TableCell>
                        <TableCell className="tableCell">TÉLÉPHONE</TableCell>
                        <TableCell className="tableCell">EMAIL</TableCell>
                        <TableCell className="tableCell">VILLE</TableCell>
                        <TableCell className="tableCell">STATUS</TableCell>
                        <TableCell className="tableCell">ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        clinics && clinics.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.name}</TableCell>
                                <TableCell className="tableCell">{row.domain}</TableCell>
                                <TableCell className="tableCell">{row.phone}</TableCell>
                                <TableCell className="tableCell">{row.email}</TableCell>
                                <TableCell className="tableCell">{row.city ? row.city : '--'}</TableCell>
                                <TableCell className="tableCell">
                                    <span className={`status ${row.status}`}>{row.status}</span>
                                </TableCell>
                                <TableCell className="tableCell">
                                    <Button onClick={() => handleDisplayClinicInfo(row)} endIcon={<InfoIcon />}
                                        variant="outlined" color='success'
                                    >
                                        Détail
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <ClinicDetails
            open={open}
            handleClose={handleClose}
        />
    </>

}

export default ClinicsTable