import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { appReducer, billsReducer, clinicsReducer, eventsReducer, foldersReducer, professionalsReducer } from './reducers/appReducer';
import { userLoginReducer } from './reducers/authReducer';
import { statsReducer } from './reducers/statsReducer';
import { chatReducer } from './reducers/chatReducer';

const reducers = combineReducers({
    app: appReducer,
    auth: userLoginReducer,
    folders: foldersReducer,
    events: eventsReducer,
    professionals: professionalsReducer,
    clinics: clinicsReducer,
    stats: statsReducer,
    chat: chatReducer,
    bills: billsReducer
});

const store = createStore(
    reducers,
    `${process.env.REACT_APP_API_ENVIRONNEMENT}` === 'PRODUCTION'
        ? compose(applyMiddleware(thunk))
        : composeWithDevTools(applyMiddleware(thunk))
);

export default store;