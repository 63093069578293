import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/system';
import { dateParserLong, dateParserShort } from '../../utils/functions';
import { DialogContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { findFolderById } from '../../redux/actions/appActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BillDetails({ open, handleClose, bill }) {

    const [total, setTotal] = React.useState(0);
    const [subTotal, setSubTotal] = React.useState(0);

    const { clinical } = useSelector(state => state.clinics);
    const { folder } = useSelector(state => state.folders);

    React.useEffect(() => {
        let sum = 0;
        bill?.invoices?.map(inv => {
            sum = sum + parseFloat(inv.amount)

            setSubTotal(sum);

            if (bill?.invoices[0]?.tax && parseFloat(bill?.invoices[0]?.tax) > 0) {
                let taxCompute = parseFloat(bill?.invoices[0]?.tax) / 100
                setTotal(sum - (sum * taxCompute));
            } else {
                setTotal(sum);
            }


        })
    }, [bill?.invoices]);


    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(findFolderById(bill?.folder));
    }, [bill, dispatch])

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#e6e6e6', color: 'black' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase' }} variant="body2" component="div">
                        Détail de la facture numéro {bill?.number}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent bgcolor='red' >
                <Box m={1} display="flex" flexDirection="column" justifyContent="center" width="100%" alignItems="center" >
                    <Box display="flex" flexDirection="column" width="100%" alignItems="center" alignContent="center" p={3}>
                        <Typography m={3} textAlign="center" variant='h5'>Facture n° {bill?.number}</Typography>

                        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
                            <Box display="flex" flexDirection="column" width={"70%"} alignSelf="center">
                                {folder && <Box mt={3} mb={3}>
                                    <Box>{folder?.number ? 'N° dossier: ' + folder?.number : 'N° dossier ' + folder?.id}</Box>
                                    <Box> {folder?.patientFirstname + ' ' + folder?.patientLastname}</Box>
                                    <Box>{folder?.patientBirthday && 'Né(e) le ' + dateParserShort(folder?.patientBirthday)} {folder?.city && ' à ' + folder?.city} {folder?.country && ' ' + folder?.country}</Box>
                                    <Box> {folder?.phone && 'Téléphone: ' + folder?.phone}</Box>
                                </Box>
                                }
                            </Box>

                            {folder &&
                                <Box display="flex" flexDirection="column">
                                    <Typography variant='body1' >{clinical?.name}</Typography>
                                    <Typography variant='body1' >Adresse: {clinical?.address}</Typography>
                                    <Typography variant='body1' >Ville: {clinical?.city}</Typography>
                                    <Typography variant='body1' >Téléphone {clinical?.phone}</Typography>
                                </Box>
                            }

                        </Box>

                    </Box>

                    <Box display="flex" flexDirection="column" width="70%" bgcolor={"#f2f2f2"}>
                        {bill?.invoices && bill?.invoices.map(invoice => (
                            <Box display="flex" m={1} key={invoice?.description} bgcolor='white' p={2}>
                                <Box flex={3}>{invoice?.description}</Box>
                                <Box flex={2}>{dateParserLong(new Date())}</Box>
                                <Box flex={1}>{invoice?.amount} UM</Box>
                            </Box>
                        ))}

                        {bill?.invoices &&
                            <>
                                <Box display="flex" m={1}>
                                    <Box flex={3}></Box>
                                    <Box flex={2}></Box>
                                    <Box flex={1} bgcolor='white' p={2}>Total hors taxe: {subTotal} UM</Box>
                                </Box>
                                <Box display="flex" m={1}>
                                    <Box flex={3}></Box>
                                    <Box flex={2}></Box>

                                    <Box flex={1} bgcolor='white' p={2}>Tva: {bill?.invoices[0]?.tax}%</Box>
                                </Box>

                                <Box display="flex" m={1}>
                                    <Box flex={3}></Box>
                                    <Box flex={2}></Box>
                                    <Box flex={1} bgcolor='white' p={2}>Total TTC: {total} UM</Box>
                                </Box>
                            </>
                        }


                    </Box>

                </Box>
            </DialogContent>

        </Dialog>
    );
}