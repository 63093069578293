import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { createClinic, findAllClinics, findFoldersByClinic } from '../../redux/actions/appActions';
import { useEffect } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { dateParserShort } from '../../utils/functions';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


export default function NewHospitalization({ open, handleClose }) {
  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [folder, setFolder] = React.useState('');

  const [start, setStart] = useState(dayjs(new Date()));
  const [end, setEnd] = useState(null);

  const { user } = useSelector(state => state.auth);

  const { folders } = useSelector(state => state.folders);


  const dispatch = useDispatch();

  const handleStartChange = (newValue) => {
    setStart(newValue);
  };

  const handleEndChange = (newValue) => {
    setEnd(newValue);
  };



  useEffect(() => {
    dispatch(findFoldersByClinic(user?.clinic));
  }, [dispatch, user])

  const handleRegister = (e) => {
    e.preventDefault();
    if (user && user.clinic !== '' && user.clinic !== null && user.clinic !== 'undefine') {
      dispatch(createClinic({
        name,
        domain,
        phone,
        description,
        email,
        address,
        city
      }));

      dispatch(findAllClinics());
    }

    handleClose();
  }

  const close = () => {
    handleClose();
  }

  const handleChangeFolder = (event) => {
    setFolder(event.target.value);
    console.log(event.target.value)
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>NOUVELLE HOSPITALISATION</DialogTitle>
      <DialogContent>
        <form onSubmit={handleRegister}>
          <FormControl sx={{ mt: 1, width: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">PATIENT</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={folder}
              onChange={handleChangeFolder}
              label="Type de consultation"
              sx={{ width: "100%" }}
            >
              <MenuItem value="">
                <em></em>
              </MenuItem >
              {folders && folders.map(fold => (
                <MenuItem
                  value={fold}
                  sx={{ fontSize: "14px" }}
                >
                  {fold?.patientFirstname + ' ' + fold?.patientLastname + ' - Né(e) le : ' + dateParserShort(fold?.patientBirthday) + ' - Tel: ' + fold?.patientPhone + ' - N° Dossier: ' + (fold?.number ? fold?.number : fold?.id)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {folder && <Box mt={3} mb={3}>
            <Box>{folder?.number ? 'N° dossier: ' + folder?.number : 'N° dossier ' + folder?.id}</Box>
            <Box> {folder?.patientFirstname + ' ' + folder?.patientLastname}</Box>
            <Box>{folder?.patientBirthday && 'Né(e) le ' + dateParserShort(folder?.patientBirthday)} {folder?.city && ' à ' + folder?.city} {folder?.country && ' ' + folder?.country}</Box>
            <Box> {folder?.phone && 'Téléphone: ' + folder?.phone}</Box>
          </Box>
          }


          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="NOTE"
            type="text"
            multiline={true}
            rows={3}
            fullWidth
            onChange={e => setAddress(e.target.value)}

          />

          <FormControl variant="standard" sx={{ mt: 2, width: "100%" }}>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="DATE D'ENTRÉE"
                value={start}
                onChange={handleStartChange}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl variant="standard" sx={{ mt: 2, width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="DATE DE SORTIE"
                value={end}
                onChange={handleEndChange}
              />
            </LocalizationProvider>
          </FormControl>

        </form>



      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Annuler</Button>
        <Button type='submit' onClick={handleRegister}>Valider</Button>
      </DialogActions>
    </Dialog >
  );
}