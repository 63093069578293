
import React from 'react'
import { useSelector } from 'react-redux'
import Admins from '../../components/Admins'
import Folders from '../../components/Folders'
import Dashboard from '../../components/dashboard/Dashboard'
import Sidebar from '../../components/shared/Sidebar'
import Topbar from '../../components/shared/Topbar'
import Appointments from '../../components/Appointments'
import Platform from '../../components/Platform'
import Profil from '../../components/Profil'
import Chat from '../../components/Chat'
import Hospitalization from '../../components/Hospitalization'
import Billing from '../../components/Billing'


const Home = () => {
    const { currentComponent } = useSelector(state => state.app);

    return (
        <>
            <Sidebar />
            <main className='content'>
                <Topbar />
                {
                    currentComponent === 'Dashboard' ? <Dashboard />
                        : currentComponent === 'Dossiers médicaux encours' ? <Folders status="PENDING" />
                            : currentComponent === 'Dossiers médicaux archivés' ? <Folders status="CLOSED" />
                                : currentComponent === "Hospitalisations" ? <Hospitalization />
                                    : currentComponent === 'Espace facturation' ? <Billing />
                                        : currentComponent === 'Liste des médecins' ? <Admins />
                                            : currentComponent === 'Mes rendez-vous' ? <Appointments target="ME" />
                                                : currentComponent === "Rendez-vous de l'équipe" ? <Appointments target="TEAM" />
                                                    : currentComponent === "Liste des cliniques" ? <Platform target="TEAM" />
                                                        : currentComponent === "Mon profil" ? <Profil />
                                                            : currentComponent === "Echanger avec un collègue" ? <Chat />
                                                                : <Dashboard />

                }

            </main>
        </>

    )
}

export default Home