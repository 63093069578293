import React, { useEffect } from 'react'
import FoldersTable from './shared/table/FoldersTable'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { findFoldersByClinic, findFoldersByProfessional } from '../redux/actions/appActions';
import NewFolder from './modals/NewFolder';
import Nofications from './shared/Nofications';

const Folders = ({ status }) => {
    const [open, setOpen] = React.useState(false);
    const [openNotif, setOpenNotif] = React.useState(false);

    const { user } = useSelector(state => state.auth);
    const { loading, newFolder, success, error } = useSelector(state => state.folders);

    const handleCloseNotif = () => {
        setOpenNotif(false);
        dispatch({ type: 'CLEAN_FOLDER_MESSAGES' });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if ((newFolder || (user && user?.id)) && user?.role === "Medical secretary") {
            dispatch(findFoldersByClinic(user?.clinic));
        }
        else if (newFolder || (user && user?.id)) {
            dispatch(findFoldersByProfessional(user?.id));
        }
    }, [dispatch, user, newFolder]);

    useEffect(() => {
        if (success || error) {
            setOpenNotif(true)
        }
    }, [success, error]);

    return (
        <div>
            <div className="listContainer">
                <div className="traderTitle">
                    <span className='standardTitle'>Dossiers médicaux {status === 'PENDING' ? 'en cours' : status === 'CLOSED' ? 'archivés' : ''}</span>
                    {
                        status === 'PENDING' &&
                        <Button onClick={() => setOpen(true)} endIcon={<AddCircleIcon />}
                            variant="outlined" color='success'
                        >
                            Nouveau dossier medical
                        </Button>
                    }
                </div>
                {loading &&
                    <div className='circularProgress'>
                        <CircularProgress color="success" />
                    </div>
                }

                <FoldersTable status={status} />
            </div>
            <NewFolder
                open={open}
                handleClose={handleClose}
            />
            <Nofications
                open={openNotif}
                handleClose={handleCloseNotif}
                transition="TransitionLeft"
                message={
                    success ? 'Dossier médical créé avec succés' :
                        error ? "Erreur lors de l'ajout d'un dossier médical" :
                            "Erreur technique lors de la création d'un dossier médical"
                }
                severity={success ? 'success' : 'error'}
            />
        </div>
    )
}

export default Folders