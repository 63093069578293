import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { createClinic, findAllClinics } from '../../redux/actions/appActions';

export default function NewClinic({ open, handleClose }) {
  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');

  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const handleRegister = (e) => {
    e.preventDefault();
    if (user && user.clinic !== '' && user.clinic !== null && user.clinic !== 'undefine') {
      dispatch(createClinic({
        name,
        domain,
        phone,
        description,
        email,
        address,
        city
      }));

      dispatch(findAllClinics());
    }

    handleClose();
  }

  const close = () => {
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>AJOUT D'UN NOUVEAU CLINIQUE</DialogTitle>
      <DialogContent>
        <form onSubmit={handleRegister}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nom du clinique"
            type="text"
            fullWidth
            onChange={e => setName(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="domain"
            label="Spécialtés"
            placeholder='Ex Multi discipline'
            type="text"
            fullWidth
            onChange={e => setDomain(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description brèf"
            type="text"
            fullWidth
            onChange={e => setDescription(e.target.value)}
            multiline
            rows={2}
          />

          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="Téléphone"
            type="text"
            fullWidth
            onChange={e => setPhone(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            onChange={e => setEmail(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="vity"
            label="Ville"
            type="text"
            fullWidth
            onChange={e => setCity(e.target.value)}
            required
          />

          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="Adresse"
            type="text"
            fullWidth
            onChange={e => setAddress(e.target.value)}

          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Annuler</Button>
        <Button type='submit' onClick={handleRegister}>Valider</Button>
      </DialogActions>
    </Dialog>
  );
}