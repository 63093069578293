import axios from 'axios';
import Cookies from 'universal-cookie';
import { LOGOUT } from './authActions';

export const COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_REQUEST = 'COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_REQUEST';
export const COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_SUCCESS = 'COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_SUCCESS';
export const COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_FAIL = 'COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_FAIL';

export const COUNT_TOTAL_FOLDERS_BY_CLINICAL_REQUEST = 'COUNT_TOTAL_FOLDERS_BY_CLINICAL_REQUEST';
export const COUNT_TOTAL_FOLDERS_BY_CLINICAL_SUCCESS = 'COUNT_TOTAL_FOLDERS_BY_CLINICAL_SUCCESS';
export const TCOUNT_TOTAL_FOLDERS_BY_CLINICAL_FAIL = 'COUNT_TOTAL_FOLDERS_BY_CLINICAL_FAIL';

export const countTotalFoldersByProfessional = (professional) => async (dispatch) => {
    dispatch({ type: COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_REQUEST, payload: professional });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/stats/count-folders-by-professional/${professional}`, config, { withCredentials: true })

        dispatch({ type: COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const countTotalFoldersByClinical = (clinic) => async (dispatch) => {
    dispatch({ type: COUNT_TOTAL_FOLDERS_BY_CLINICAL_REQUEST, payload: clinic });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/stats/count-folders-by-clinical/${clinic}`, config, { withCredentials: true })

        dispatch({ type: COUNT_TOTAL_FOLDERS_BY_CLINICAL_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: TCOUNT_TOTAL_FOLDERS_BY_CLINICAL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

