import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClinicsTable from './shared/table/ClinicsTable';
import NewClinic from './modals/NewClinic';
import Nofications from './shared/Nofications';
import { useEffect } from 'react';

const Platform = () => {
    const [open, setOpen] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);

    const { user } = useSelector(state => state.auth);
    const { success, error } = useSelector(state => state.clinics);
    const { success: profSuccess, error: profError } = useSelector(state => state.clinics);

    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseNotif = () => {
        setOpenNotif(false);
        dispatch({ type: 'CLEAN_CLINIC_MESSAGES' });
        dispatch({ type: 'CLEAN_PROFESSIONAL_MESSAGES' });
    };

    useEffect(() => {
        if (success || error) {
            setOpenNotif(true)
        }
    }, [success, error])

    useEffect(() => {
        if (profSuccess || profError) {
            setOpenNotif(true)
        }
    }, [profSuccess, profError])

    return (
        <div>
            <div className="listContainer">
                <div className="traderTitle">

                    <span className='standardTitle'>Liste des cliniques</span>
                    {user?.role === 'Platfom administrator' &&
                        <AddCircleIcon
                            className='iconButton'
                            color="success"
                            onClick={() => setOpen(true)}
                        />}
                </div>
                <ClinicsTable />
            </div>

            <NewClinic
                open={open}
                handleClose={handleClose}
            />
            <Nofications
                open={openNotif}
                handleClose={handleCloseNotif}
                transition="TransitionLeft"
                message={success ? 'Clinique ajouté avec succés' : error ? "Erreur lors de l'ajout d'un clinique" : "Erreur technique lors de la création du clinique"}
                severity={success ? 'success' : 'error'}
            />
        </div>
    )
}

export default Platform