import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { createSheet, findSheetsById } from '../../redux/actions/appActions';
import { Box } from '@mui/system';
import { Divider, Radio, Typography } from '@mui/material';
import { calculateAge, calculateImc } from '../../utils/functions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function NewSheet({ open, handleClose, folder }) {
  const [description, setDescription] = useState('');
  const [prescription, setPrescription] = useState('');
  const [isVaccine, setIsVaccine] = useState(false);
  const [vaccine, setVaccine] = useState('');
  const [temperature, setTemperature] = useState('');
  const [bloodPresure, setBloodPresure] = useState('');
  const [cardiacFrequency, setCardiacFrequency] = useState('');
  const [respiratoryRate, setRespiratoryRate] = useState('');
  const [complementaryExams, setComplementaryExams] = useState('');
  const [diagnosisEvoked, setDiagnosisEvoked] = useState('');
  const [size, setSize] = useState('');
  const [weight, setWeight] = useState('');
  const [oxygenSaturation, setOxygenSaturation] = useState('');
  const [evaluationFrequency, setEvaluationFrequency] = useState('Mois');
  const [totalYear, setTotalYear] = useState();
  const [totalMonth, setTotalMonth] = useState();

  const { user } = useSelector(state => state.auth);
  const { error } = useSelector(state => state.folders);

  const dispatch = useDispatch();

  const handleRegister = (e) => {
    e.preventDefault();

    dispatch(createSheet({
      description,
      isPrescription: prescription ? true : false,
      prescription,
      isVaccine,
      vaccine,
      temperature,
      bloodPresure,
      cardiacFrequency,
      respiratoryRate,
      imc: calculateImc(weight, size),
      complementaryExams,
      diagnosisEvoked,
      size,
      weight,
      oxygenSaturation,
      clinic: user.clinic,
      professional: user.id,
      folder: folder.id,
      age: calculateAge(folder?.patientBirthday),
      totalYear,
      totalMonth,

    }
    ));
    if (!error) {
      dispatch(findSheetsById(folder.id));
      resetForm();
      handleClose();
    }

  }

  const resetForm = () => {
    setDescription('');
    setPrescription('');
    setIsVaccine(false);
    setVaccine('');
    setTemperature('');
    setBloodPresure('');
    setCardiacFrequency('');
    setRespiratoryRate('');
    setComplementaryExams('');
    setDiagnosisEvoked('');
    setSize('');
    setWeight('');
    setOxygenSaturation('');
    setEvaluationFrequency('Mois');
    setTotalYear();
    setTotalMonth();
  }


  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ backgroundColor: "#595959", color: "white", fontSize: "14px" }}>CRÉATION D'UNE NOUVELLE NOTE DE CONSULTATION</DialogTitle>
      <DialogContent>
        <form onSubmit={handleRegister}>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Note"
            type="text"
            fullWidth
            multiline
            rows={2}
            onChange={e => setDescription(e.target.value)}
          />

          <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
            <Box display="flex" alignItems="center" flex={1} mr={1}>
              <TextField
                autoFocus
                margin="dense"
                id="wight"
                label="Poids"
                type="text"
                fullWidth

                onChange={e => setWeight(e.target.value)}
              />
            </Box>

            <Box display="flex" alignItems="center" flex={1} ml={1}>
              <TextField
                autoFocus
                margin="dense"
                id="size"
                label="Taille"
                type="text"
                fullWidth
                onChange={e => setSize(e.target.value)}
              />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
            <Box display="flex" alignItems="center" flex={1} mr={1}>
              <TextField
                autoFocus
                margin="dense"
                id="temperature"
                label="Température"
                type="text"
                fullWidth
                onChange={e => setTemperature(e.target.value)}
              />
            </Box>

            <Box display="flex" alignItems="center" flex={1} ml={1}>
              <TextField
                autoFocus
                margin="dense"
                id="cardiacFrequency"
                label="Freq. cardiaque"
                type="text"
                fullWidth
                onChange={e => setCardiacFrequency(e.target.value)}
              />
            </Box>

            <Box display="flex" alignItems="center" flex={1} ml={2}>
              <TextField
                autoFocus
                margin="dense"
                id="respiratoryRate"
                label="Freq. respiratoire"
                type="text"
                fullWidth
                onChange={e => setRespiratoryRate(e.target.value)}
              />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
            <Box display="flex" alignItems="center" flex={1} mr={1}>
              <TextField
                autoFocus
                margin="dense"
                id="bloodPresure"
                label="Tension"
                type="text"
                fullWidth
                onChange={e => setBloodPresure(e.target.value)}
              />
            </Box>
            <Box display="flex" alignItems="center" flex={1} >
              <TextField
                autoFocus
                margin="dense"
                id="oxygenSaturation"
                label="Saturation d'oxygène"
                type="text"
                fullWidth
                onChange={e => setOxygenSaturation(e.target.value)}
              />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" m={1}>
            <Box display="flex" flex={4}>
              <Typography variant='body1' mr={2}>Fréquence d'evaluation</Typography>
            </Box>
            <Box display="flex" alignItems="center" flex={1} mr={4}>
              <Typography variant='body1'>Mois</Typography>
              <Radio
                checked={evaluationFrequency === 'Mois'}
                onChange={() => setEvaluationFrequency("Mois")}
                value={false}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
              />
            </Box>

            <Box display="flex" alignItems="center" flex={1}>
              <Typography variant='body1'>Année</Typography>
              <Radio
                checked={evaluationFrequency === 'Année'}
                onChange={() => setEvaluationFrequency("Année")}
                value={true}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
              />
            </Box>
          </Box>

          {evaluationFrequency === 'Mois' &&
            <TextField
              autoFocus
              margin="dense"
              id="prescription"
              label="Précisez le nombre de mois"
              type="text"
              fullWidth
              onChange={e => setTotalMonth(e.target.value)}
            />
          }

          {evaluationFrequency === 'Année' &&
            <TextField
              autoFocus
              margin="dense"
              id="evaluationFrequency"
              label="Précisez le nombre d'années"
              type="text"
              fullWidth
              onChange={e => setTotalYear(e.target.value)}
            />
          }
          <Box sx={{ marginBottom: 2, marginTop: 1 }}>
            <ReactQuill theme="snow" value={complementaryExams} onChange={setComplementaryExams} placeholder="Examens complémentaires" />
          </Box>
          <Box sx={{ marginBottom: 2, }}>
            <ReactQuill theme="snow" value={diagnosisEvoked} onChange={setDiagnosisEvoked} placeholder="Diagnostic évoqué" />
          </Box>
          <Box sx={{ marginBottom: 2, }}>
            <ReactQuill theme="snow" value={prescription} onChange={setPrescription} placeholder="Ordonnance" />
          </Box>

          <Divider />

          <Box display="flex" alignItems="center" m={1}>
            <Box display="flex" flex={4}>
              <Typography variant='body1' mr={2}>Procuration d'un vacciné ?</Typography>
            </Box>
            <Box display="flex" alignItems="center" flex={1} mr={4}>
              <Typography variant='body1'>Non</Typography>
              <Radio
                checked={!isVaccine}
                onChange={() => setIsVaccine(false)}
                value={false}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
              />
            </Box>

            <Box display="flex" alignItems="center" flex={1}>
              <Typography variant='body1'>Oui</Typography>
              <Radio
                checked={isVaccine}
                onChange={() => setIsVaccine(true)}
                value={true}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
              />
            </Box>
          </Box>
          {isVaccine &&
            <ReactQuill theme="snow" value={vaccine} onChange={setVaccine} placeholder="Détail du vaccin" />
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button type='submit' onClick={handleRegister}>Valider</Button>
      </DialogActions>
    </Dialog>
  );
}