import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { updateProfessionalPassword } from '../../redux/actions/authActions';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function ChangePassword({ open, handleClose }) {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [wrongPassWord, setWrongPassword] = useState('');

  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();


  const handleRegister = (e) => {
    e.preventDefault();
    setWrongPassword('');

    if (password !== "password") {
      setWrongPassword("L'actuel mot de passe n'est pas bon");
    } else if (newPassword?.length < 6) {
      setWrongPassword("Le nouveau mot de passe doit être au moins de 6 caractères")
    } else {
      dispatch(updateProfessionalPassword(newPassword, user.id));
      handleClose();
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle sx={{ backgroundColor: "#595959", color: "white", fontSize: "14px", marginBottom: 2 }}>CHANGER MON MOT DE PASSE PAR DÉFAUT</DialogTitle>
      <DialogContent>

        <form onSubmit={handleRegister}>
          <Box display="flex" flexDirection="column">
            <Typography variant='caption' sx={{ color: 'red', textAlign: 'center', mb: 2 }}>{wrongPassWord}</Typography>

            <FormControl variant="outlined" sx={{ marginBottom: 2 }}>
              <InputLabel htmlFor="outlined-adornment-password">Nouveau mot de passe</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={100}
              />
            </FormControl>

            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Mot de passe actuel</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={100}
              />
            </FormControl>
          </Box>
        </form>

      </DialogContent>
      <DialogActions>
        <Button variant="contained" type='submit' endIcon={<DoneIcon />} onClick={handleRegister} sx={{ backgroundColor: 'black' }}>Valider</Button>
        <Button variant="outlined" onClick={handleClose} endIcon={<ClearIcon />} >Annuler</Button>
      </DialogActions>

    </Dialog>
  );
}