import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { dateParserShort, dateParserLong } from '../../utils/functions'

const BillingDetails = ({ clinical, selectedFolder, invoices }) => {
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);

    useEffect(() => {
        let sum = 0;
        invoices?.map(inv => {
            sum = sum + parseFloat(inv.amount)

            setSubTotal(sum);

            if (invoices[0]?.tax && parseFloat(invoices[0]?.tax) > 0) {
                let taxCompute = parseFloat(invoices[0]?.tax) / 100
                setTotal(sum - (sum * taxCompute));
            } else {
                setTotal(sum);
            }


        })
    }, [invoices]);

    return (
        <Box bgcolor={"#f2f2f2"} >
            <Box display="flex" flexDirection="column" width="100%" alignItems="center" alignContent="center" p={3}>
                <Typography textAlign="center" variant='h5'>Facture client</Typography>

                <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
                    <Box display="flex" flexDirection="column" width={"70%"} alignSelf="center">
                        {selectedFolder && <Box mt={3} mb={3}>
                            <Box>{selectedFolder?.number ? 'N° dossier: ' + selectedFolder?.number : 'N° dossier ' + selectedFolder?.id}</Box>
                            <Box> {selectedFolder?.patientFirstname + ' ' + selectedFolder?.patientLastname}</Box>
                            <Box>{selectedFolder?.patientBirthday && 'Né(e) le ' + dateParserShort(selectedFolder?.patientBirthday)} {selectedFolder?.city && ' à ' + selectedFolder?.city} {selectedFolder?.country && ' ' + selectedFolder?.country}</Box>
                            <Box> {selectedFolder?.phone && 'Téléphone: ' + selectedFolder?.phone}</Box>
                        </Box>
                        }
                    </Box>

                    {selectedFolder &&
                        <Box display="flex" flexDirection="column">
                            <Typography variant='body1' >{clinical?.name}</Typography>
                            <Typography variant='body1' >Adresse: {clinical?.address}</Typography>
                            <Typography variant='body1' >Ville: {clinical?.city}</Typography>
                            <Typography variant='body1' >Téléphone {clinical?.phone}</Typography>
                        </Box>
                    }

                </Box>

            </Box>

            <Box display="flex" flexDirection="column" height="700px">
                {invoices && invoices.map(invoice => (
                    <Box display="flex" m={1} key={invoice?.description} bgcolor='white' p={2}>
                        <Box flex={3}>{invoice?.description}</Box>
                        <Box flex={2}>{dateParserLong(new Date())}</Box>
                        <Box flex={1}>{invoice?.amount} UM</Box>
                    </Box>
                ))}

                {invoices &&
                    <>


                        <Box display="flex" m={1}>
                            <Box flex={3}></Box>
                            <Box flex={2}></Box>
                            <Box flex={1} bgcolor='white' p={2}>Total hors taxe: {subTotal} UM</Box>
                        </Box>
                        <Box display="flex" m={1}>
                            <Box flex={3}></Box>
                            <Box flex={2}></Box>

                            <Box flex={1} bgcolor='white' p={2}>Tva: {invoices[0]?.tax}%</Box>
                        </Box>

                        <Box display="flex" m={1}>
                            <Box flex={3}></Box>
                            <Box flex={2}></Box>
                            <Box flex={1} bgcolor='white' p={2}>Total TTC: {total} UM</Box>
                        </Box>
                    </>
                }


            </Box>
        </Box>
    )
}

export default BillingDetails