import axios from 'axios';
import Cookies from 'universal-cookie';
import { LOGOUT } from './authActions';
export const SET_COMPONENT = 'SET_COMPONENT';
export const FIND_USERS_BY_ROLE = 'FIND_USERS_BY_ROLE';
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAIL = 'USER_INFO_FAIL';

export const GET_TRADERS_REQUEST = 'GET_TRADERS_REQUEST';
export const GET_TRADERS_SUCCESS = 'GET_TRADERS_SUCCESS';
export const GET_TRADERS_FAIL = 'GET_TRADERS_FAIL';

export const GET_FOLDERS_REQUEST = 'GET_FOLDERS_REQUEST';
export const GET_FOLDERS_SUCCESS = 'GET_FOLDERS_SUCCESS';
export const GET_FOLDERS_FAIL = 'GET_FOLDERS_FAIL';

export const SEARCH_FOLDERS_REQUEST = 'SEARCH_FOLDERS_REQUEST';
export const SEARCH_FOLDERS_SUCCESS = 'SEARCH_FOLDERS_SUCCESS';
export const SEARCH_FOLDERS_FAIL = 'SEARCH_FOLDERS_FAIL';

export const GET_SHEETS_REQUEST = 'GET_SHEETS_REQUEST';
export const GET_SHEETS_SUCCESS = 'GET_SHEETS_SUCCESS';
export const GET_SHEETS_FAIL = 'GET_SHEETS_FAIL';

export const GET_ALL_PROFESSIONALS_REQUEST = 'GET_ALL_PROFESSIONALS_REQUEST';
export const GET_ALL_PROFESSIONALS_SUCCESS = 'GET_ALL_PROFESSIONALS_SUCCESS';
export const GET_ALL_PROFESSIONALS_FAIL = 'GET_ALL_PROFESSIONALS_FAIL';

export const FIND_SHEETS_BY_FOLDER_REQUEST = 'FIND_SHEETS_BY_FOLDER_REQUEST';
export const FIND_SHEETS_BY_FOLDER_SUCCESS = 'FIND_SHEETS_BY_FOLDER_SUCCESS';
export const FIND_SHEETS_BY_FOLDER_FAIL = 'FIND_SHEETS_BY_FOLDER_FAIL';

export const FIND_FOLDER_BY_ID_REQUEST = 'FIND_FOLDER_BY_ID_REQUEST';
export const FIND_FOLDER_BY_ID_SUCCESS = 'FIND_FOLDER_BY_ID_SUCCESS';
export const FIND_FOLDER_BY_ID_FAIL = 'FIND_FOLDER_BY_ID_FAIL';

export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAIL = 'CREATE_FOLDER_FAIL';

export const CREATE_SHEET_REQUEST = 'CREATE_SHEET_REQUEST';
export const CREATE_SHEET_SUCCESS = 'CREATE_SHEET_SUCCESS';
export const CREATE_SHEET_FAIL = 'CREATE_SHEET_FAIL';

export const UPDATE_FOLDER_REQUEST = 'UPDATE_FOLDER_REQUEST';
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS';
export const UPDATE_FOLDER_FAIL = 'UPDATE_FOLDER_FAIL';

export const UPDATE_CLINIC_REQUEST = 'UPDATE_CLINIC_REQUEST';
export const UPDATE_CLINIC_SUCCESS = 'UPDATE_CLINIC_SUCCESS';
export const UPDATE_CLINIC_FAIL = 'UPDATE_CLINIC_FAIL';

export const UPDATE_PROFESSIONAL_REQUEST = 'UPDATE_PROFESSIONAL_REQUEST';
export const UPDATE_PROFESSIONAL_SUCCESS = 'UPDATE_PROFESSIONAL_SUCCESS';
export const UPDATE_PROFESSIONAL_FAIL = 'UPDATE_PROFESSIONAL_FAIL';

export const UPDATE_SHEET_REQUEST = 'UPDATE_SHEET_REQUEST';
export const UPDATE_SHEET_SUCCESS = 'UPDATE_SHEET_SUCCESS';
export const UPDATE_SHEET_FAIL = 'UPDATE_SHEET_FAIL';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';

export const FIND_EVENTS_BY_PROFESSIONAL_REQUEST = "FIND_EVENTS_BY_PROFESSIONAL_REQUEST";
export const FIND_EVENTS_BY_PROFESSIONAL_SUCCESS = "FIND_EVENTS_BY_PROFESSIONAL_SUCCESS";
export const FIND_EVENTS_BY_PROFESSIONAL_FAIL = "FIND_EVENTS_BY_PROFESSIONAL_FAIL";

export const FIND_EVENTS_BY_CLINICAL_REQUEST = "FIND_EVENTS_BY_CLINICAL_REQUEST";
export const FIND_EVENTS_BY_CLINICAL_SUCCESS = "FIND_EVENTS_BY_CLINICAL_SUCCESS";
export const FIND_EVENTS_BY_CLINICAL_FAIL = "FIND_EVENTS_BY_CLINICAL_FAIL";

export const CREATE_PROFESSIONAL_REQUEST = "CREATE_PROFESSIONAL_REQUEST";
export const CREATE_PROFESSIONAL_SUCCESS = "CREATE_PROFESSIONAL_SUCCESS";
export const CREATE_PROFESSIONAL_FAIL = "CREATE_PROFESSIONAL_FAIL";

export const FIND_PROFESSIONAL_BY_CLINIC_REQUEST = "FIND_PROFESSIONAL_BY_CLINIC_REQUEST";
export const FIND_PROFESSIONAL_BY_CLINIC_SUCCESS = "FIND_PROFESSIONAL_BY_CLINIC_SUCCESS";
export const FIND_PROFESSIONAL_BY_CLINIC_FAIL = "FIND_PROFESSIONAL_BY_CLINIC_FAIL";

export const FIND_BILL_BY_CLINIC_REQUEST = "FIND_BILL_BY_CLINIC_REQUEST";
export const FIND_BILL_BY_CLINIC_SUCCESS = "FIND_BILL_BY_CLINIC_SUCCESS";
export const FIND_BILL_BY_CLINIC_FAIL = "FIND_BILL_BY_CLINIC_FAIL";

export const FIND_ALL_CLINICS_REQUEST = 'FIND_ALL_CLINICS_REQUEST';
export const FIND_ALL_CLINICS_SUCCESS = 'FIND_ALL_CLINICS_SUCCESS';
export const FIND_ALL_CLINICS_FAIL = 'FIND_ALL_CLINICS_FAIL';

export const CREATE_CLINIC_REQUEST = 'CREATE_CLINIC_REQUEST';
export const CREATE_CLINIC_SUCCESS = 'CREATE_CLINIC_SUCCESS';
export const CREATE_CLINIC_FAIL = 'CREATE_CLINIC_FAIL';

export const FIND_CLINICAL_BY_ID_REQUEST = "FIND_CLINICAL_BY_ID_REQUEST";
export const FIND_CLINICAL_BY_ID_SUCCESS = "FIND_CLINICAL_BY_ID_SUCCESS";
export const FIND_CLINICAL_BY_ID_FAIL = "FIND_CLINICAL_BY_ID_FAIL";

export const CREATE_BILL_REQUEST = 'CREATE_BILL_REQUEST';
export const CREATE_BILL_SUCCESS = 'CREATE_BILL_SUCCESS';
export const CREATE_BILL_FAIL = 'CREATE_BILL_FAIL';

export const setCurrentComponent = (label) => async (dispatch) => {
    dispatch({ type: SET_COMPONENT, payload: label });
};

export const findFoldersByProfessional = (professional, page, limit) => async (dispatch) => {

    dispatch({ type: GET_FOLDERS_REQUEST, payload: professional });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/folders/find-by-professional?professional=${professional}&page=${page}&limit=${limit}`, config, { withCredentials: true })

        dispatch({ type: GET_FOLDERS_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: GET_FOLDERS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const findFoldersByClinic = (clinic, page, limit) => async (dispatch) => {

    dispatch({ type: GET_FOLDERS_REQUEST, payload: clinic });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/folders/find-by-clinical?clinic=${clinic}&page=${page}&limit=${limit}`, config, { withCredentials: true })

        dispatch({ type: GET_FOLDERS_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: GET_FOLDERS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const findFolderById = (folderId) => async (dispatch) => {

    dispatch({ type: FIND_FOLDER_BY_ID_REQUEST, payload: folderId });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/folders/find/${folderId}`, config, { withCredentials: true })

        dispatch({ type: FIND_FOLDER_BY_ID_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: FIND_FOLDER_BY_ID_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const searchFoldersByCriteria = (criteria, clinic, status) => async (dispatch) => {
    dispatch({ type: SEARCH_FOLDERS_REQUEST });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    console.log("searchFoldersByCriteria criteria", criteria)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/folders/search?criteria=${criteria}&clinic=${clinic}&status=${status}`, config, { withCredentials: true })

        dispatch({ type: SEARCH_FOLDERS_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: SEARCH_FOLDERS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const findSheetsById = (folderId, page, limit) => async (dispatch) => {

    dispatch({ type: FIND_SHEETS_BY_FOLDER_REQUEST, payload: folderId });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/sheets/find-by-folder?folder=${folderId}&page=${page}&limit=${limit}`, config, { withCredentials: true })

        dispatch({ type: FIND_SHEETS_BY_FOLDER_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: FIND_SHEETS_BY_FOLDER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const findAllProfessionals = (page, limit) => async (dispatch) => {
    dispatch({ type: GET_ALL_PROFESSIONALS_REQUEST });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/professionals/find-all?page=${page}&limit=${limit}`, config, { withCredentials: true })

        dispatch({ type: GET_ALL_PROFESSIONALS_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: GET_ALL_PROFESSIONALS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const findSheetsByFolder = (folder) => async (dispatch) => {

    dispatch({ type: GET_SHEETS_REQUEST, payload: folder });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/sheets/find-by-folder/${folder}`, config, { withCredentials: true })

        dispatch({ type: GET_SHEETS_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: GET_SHEETS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};
export const createFolder = (data) => async (dispatch) => {
    dispatch({ type: CREATE_FOLDER_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/folders/create`, body, config, { withCredentials: true })

        dispatch({ type: CREATE_FOLDER_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: CREATE_FOLDER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const createSheet = (data) => async (dispatch) => {
    dispatch({ type: CREATE_SHEET_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/sheets/create`, body, config, { withCredentials: true })

        dispatch({ type: CREATE_SHEET_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: CREATE_SHEET_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const createEvent = (data) => async (dispatch) => {
    dispatch({ type: CREATE_EVENT_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/events/create`, body, config, { withCredentials: true })

        dispatch({ type: CREATE_EVENT_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: CREATE_EVENT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const findEventByProfessional = (data) => async (dispatch) => {
    const body = JSON.stringify(data)

    dispatch({ type: FIND_EVENTS_BY_PROFESSIONAL_REQUEST, payload: data });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };



        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/events/find-by-professional`, body, config, { withCredentials: true })

        dispatch({ type: FIND_EVENTS_BY_PROFESSIONAL_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: FIND_EVENTS_BY_PROFESSIONAL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const findEventByClinical = (data) => async (dispatch) => {
    const body = JSON.stringify(data)

    dispatch({ type: FIND_EVENTS_BY_CLINICAL_REQUEST, payload: data });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/events/find-by-clinic`, body, config, { withCredentials: true })

        dispatch({ type: FIND_EVENTS_BY_CLINICAL_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: FIND_EVENTS_BY_CLINICAL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const findClinicalById = (clinic) => async (dispatch) => {

    dispatch({ type: FIND_CLINICAL_BY_ID_REQUEST, payload: clinic });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/clinicals/find-by-id/${clinic}`, config, { withCredentials: true })

        dispatch({ type: FIND_CLINICAL_BY_ID_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: FIND_CLINICAL_BY_ID_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const createProfessional = (data) => async (dispatch) => {
    dispatch({ type: CREATE_PROFESSIONAL_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/professionals/create`, body, config, { withCredentials: true })

        dispatch({ type: CREATE_PROFESSIONAL_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: CREATE_PROFESSIONAL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const findProfessionalsByClinic = (clinicId, page, limit) => async (dispatch) => {
    dispatch({ type: FIND_PROFESSIONAL_BY_CLINIC_REQUEST, payload: clinicId });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/professionals/find-by-clinic?clinic=${clinicId}&page=${page}&limit=${limit}`, config, { withCredentials: true })

        dispatch({ type: FIND_PROFESSIONAL_BY_CLINIC_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: FIND_PROFESSIONAL_BY_CLINIC_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const findAllClinics = () => async (dispatch) => {
    dispatch({ type: FIND_ALL_CLINICS_REQUEST });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/clinicals/find-all`, config, { withCredentials: true })

        dispatch({ type: FIND_ALL_CLINICS_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: FIND_ALL_CLINICS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const createClinic = (data) => async (dispatch) => {
    dispatch({ type: CREATE_CLINIC_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/clinicals/create`, body, config, { withCredentials: true })

        dispatch({ type: CREATE_CLINIC_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: CREATE_CLINIC_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const updateFolder = (folderId, data) => async (dispatch) => {
    dispatch({ type: UPDATE_FOLDER_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}/folders/update/${folderId}`, body, config, { withCredentials: true })

        dispatch({ type: UPDATE_FOLDER_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: UPDATE_FOLDER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const updateProfessional = (professionalId, data) => async (dispatch) => {
    dispatch({ type: UPDATE_PROFESSIONAL_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}/professionals/update/${professionalId}`, body, config, { withCredentials: true })

        dispatch({ type: UPDATE_PROFESSIONAL_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: UPDATE_PROFESSIONAL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const updateClinic = (clinicId, data) => async (dispatch) => {
    dispatch({ type: UPDATE_CLINIC_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}/clinicals/update/${clinicId}`, body, config, { withCredentials: true })

        dispatch({ type: UPDATE_CLINIC_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: UPDATE_CLINIC_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const createBill = (data) => async (dispatch) => {
    dispatch({ type: CREATE_BILL_REQUEST, payload: data });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/bills/create`, body, config, { withCredentials: true })

        dispatch({ type: CREATE_BILL_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }
        dispatch({
            type: CREATE_BILL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};



export const findBillsByClinic = (clinicId, page, limit) => async (dispatch) => {
    dispatch({ type: FIND_BILL_BY_CLINIC_REQUEST, payload: clinicId });

    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/bills/find-by-clinic/${clinicId}`, config, { withCredentials: true })

        dispatch({ type: FIND_BILL_BY_CLINIC_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: FIND_BILL_BY_CLINIC_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};
