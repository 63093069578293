import React, { useEffect, useState } from 'react'
import './customTable.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { findFoldersByClinic, findFoldersByProfessional } from '../../../redux/actions/appActions';
import { dateParserShort } from '../../../utils/functions';
import FolderDetails from '../../modals/FolderDetails';
import { Box, Button, FormControl, MenuItem, Pagination, Select, Stack } from '@mui/material';


const FoldersTable = ({ status }) => {
    const [open, setOpen] = useState(false);
    const [folderList, setFolderList] = useState(false);

    const { user } = useSelector(state => state.auth);
    const { folders, folderTotalPages } = useSelector(state => state.folders);

    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);

    const dispatch = useDispatch();

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
    };

    useEffect(() => {
        if (user?.role === "Medical secretary") {
            dispatch(findFoldersByClinic(user?.clinic, page, limit));
        } else {
            dispatch(findFoldersByProfessional(user?.id, page, limit));
        }

    }, [dispatch, user, page, limit, folderTotalPages])

    useEffect(() => {
        if (folders && status === 'PENDING') {
            setFolderList(folders?.filter(fold => fold.status === "Actif"))
        } else if (folders && status === 'CLOSED') {
            setFolderList(folders?.filter(fold => fold.status === "Archivé"))
        }
    }, [status, folders])

    const handleClose = () => {
        setOpen(false);
    };

    const handleDisplayFolderInfo = (folder) => {
        setOpen(true)
        dispatch({ type: "SELECTED_FOLDER", payload: folder });
    }

    return <>
        <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='tableCell'>DOSSIER N°</TableCell>
                        {user?.role === "Medical secretary" &&
                            <TableCell className='tableCell'>MÉDECIN TRAITANT</TableCell>
                        }
                        <TableCell className="tableCell">PRENOM</TableCell>
                        <TableCell className="tableCell">NOM</TableCell>
                        <TableCell className="tableCell">DATE DE NAISSANCE</TableCell>
                        <TableCell className="tableCell">LIEU DE NAISSANCE</TableCell>
                        <TableCell className="tableCell">TÉLÉPHONE</TableCell>
                        <TableCell className="tableCell">TYPE DE SOIN</TableCell>
                        <TableCell className="tableCell">STATUS</TableCell>
                        {user?.role !== "Medical secretary" && <TableCell className="tableCell">ACTIONS</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {folderList && folderList.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell className="tableCell">{row?.number ? row.number : row?.id}</TableCell>
                            {user?.role === "Medical secretary" &&
                                <TableCell className="tableCell">{row?.professional ? 'Dr ' + row.professional?.firstName + ' ' + row.professional?.lastName : "--"}</TableCell>
                            }
                            <TableCell className="tableCell">{row.patientFirstname}</TableCell>
                            <TableCell className="tableCell">{row.patientLastname}</TableCell>
                            <TableCell className="tableCell">{dateParserShort(row.patientBirthday)}</TableCell>
                            <TableCell className="tableCell">Nouakchott</TableCell>
                            <TableCell className="tableCell">{row.patientPhone}</TableCell>
                            <TableCell className="tableCell">{row.consulationType}</TableCell>
                            <TableCell className="tableCell">
                                <span className={`status ${row.status}`}>{row.status}</span>
                            </TableCell>
                            {user?.role !== "Medical secretary" &&
                                <TableCell className="tableCell">
                                    <Button onClick={() => handleDisplayFolderInfo(row)} endIcon={<InfoIcon />}
                                        variant="outlined" color='success'
                                    >
                                        Détail
                                    </Button>
                                </TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
                <FormControl sx={{ padding: 1 }}>
                    <Select
                        value={limit}
                        onChange={handleChangeLimit}
                        label="Type de consultation"
                        sx={{ width: "100%" }}
                    >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
                <Stack spacing={2}>
                    <Pagination count={folderTotalPages ? folderTotalPages : 0} page={page} onChange={handleChange} />
                </Stack>
            </Box>
        </TableContainer>
        <FolderDetails
            open={open}
            handleClose={handleClose}
        />
    </>

}

export default FoldersTable