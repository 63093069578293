import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InputAdornment from '@mui/material/InputAdornment';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from 'react-redux';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PasswordIcon from '@mui/icons-material/Password';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { updateProfessionalPassword } from '../redux/actions/authActions';

const Profil = () => {
    const { user } = useSelector(state => state.auth);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [address, setAddress] = useState(user?.address ? user?.address : '');
    const [phone, setPhone] = useState(user?.phone ? user?.phone : '');
    const [email, setEmail] = useState(user?.email ? user?.email : '');
    const [city, setCity] = useState(user?.city ? user?.city : '');
    const [errorMsg, setErrorMsg] = useState('');

    const handleUpdatePassword = () => {

        if (newPassword !== confirmPassword) {
            setErrorMsg("Les mots de passes sont différents")
        } else {
            updateProfessionalPassword(password, user.id);
        }
    }

    const handleUpdateInformation = () => {

    }

    return (
        <Box display="flex" flexDirection="column" p={4}>
            <Box><Typography variant='h5'>Paramètres du profil</Typography></Box>
            <Box display="flex" mt={3} >
                <Box display="flex" flexDirection="column" flex={1} p={3} sx={{ borderColor: "silver", borderStyle: 'solid', borderWidth: "1px", height: '450px' }} mr={2} borderRadius="8px">
                    <Box display="flex" alignItems="center" bgcolor={"#e6e6e6"} p={2} borderRadius="10px">
                        <PersonOutlinedIcon /> <Typography ml={2} variant='body2'>Mon profil</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" bgcolor={"#e6e6e6"} p={2} mt={1} borderRadius="10px">
                        <NotificationsOutlinedIcon /> <Typography ml={2} variant='body2'>Notifications</Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" flex={3}>
                    <Box display="flex" flexDirection="column" alignItems="center" flex={1} p={3} sx={{ borderColor: "silver", borderStyle: 'solid', borderWidth: "1px" }} mr={2} mb={6} borderRadius="8px">
                        <Typography variant='body2'>Modifier mon mot de passe</Typography>
                        <Box sx={{ '& > :not(style)': { m: 1 } }} width="100%" display="flex" alignItems="center" justifyContent="space-evenly">
                            <TextField
                                id="password"
                                label="Mot de passe actuel"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOpenIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                value={password}
                                type="password"
                                onChange={(event) => setPassword(event.target.value)}
                            />

                            <TextField
                                id="newPassword"
                                label="Le nouveau mot de passe"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                value={newPassword}
                                onChange={(event) => setNewPassword(event.target.value)}
                                type="password"
                            />
                            <TextField
                                id="confirmPassword"
                                label="Confirmer le nouveau mot de passe"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                type="password"
                            />

                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="outlined" endIcon={<SendIcon />} color="success" type='buton' onClick={handleUpdatePassword}>
                                        Modifier
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" alignItems="center" sx={{ borderColor: "silver", borderStyle: 'solid', borderWidth: "1px" }} mr={2} p={3} borderRadius="8px">
                        <Typography variant='body2'>Informations personnelles</Typography>
                        <Box display="flex" flex={3} p={2} borderRadius="8px">

                            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                                <TextField
                                    id="username"
                                    label="Nom et Prénom"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={user?.firstName + ' ' + user?.lastName}
                                    disabled
                                />

                                <TextField
                                    id="clinic"
                                    label="Établissment de santé"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountBalanceIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={user?.clinic?.name}
                                    disabled
                                />

                                <TextField
                                    id="email"
                                    label="Adresse Mail"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AlternateEmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                                <TextField
                                    id="phone"
                                    label="N° téléphone"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIphoneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                />
                                <TextField
                                    id="city"
                                    label="Ville"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)}
                                />
                                <TextField
                                    id="address"
                                    label="Adresse"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={address}
                                    onChange={(event) => setAddress(event.target.value)}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="outlined" type='button' onClick={handleUpdateInformation} endIcon={<SendIcon />} color="success">
                                            Modifier
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Profil