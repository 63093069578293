import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

const postion = {
    vertical: 'top',
    horizontal: 'center',
}

const Nofications = ({ open, handleClose, transition, severity, message }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: postion.vertical, horizontal: postion.horizontal }}
            TransitionComponent={transition}
        >
            <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default Nofications;