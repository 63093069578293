import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { ProSidebarProvider, Menu, MenuItem } from 'react-pro-sidebar';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import logo from '../../assets/images/logo.png';
import { setCurrentComponent } from '../../redux/actions/appActions';
import { useDispatch, useSelector } from 'react-redux';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';

const Item = ({ title, icon, selected, setSelected }) => {
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: "#040509",
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};


const Sidebar = () => {
    const [selected, setSelected] = useState("Dashboard");

    const { user } = useSelector(state => state.auth);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setCurrentComponent(selected))
    }, [selected, dispatch])

    return (
        <Box
            sx={{
                width: "300px",
                // backgroundColor: "#00a3cc",
                // backgroundColor: "#33ff77"
                backgroundColor: "#e6e6e6"
            }}
        >

            <ProSidebarProvider color="#e6e6e6" >
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        style={{
                            margin: "50px 0 20px 0",
                            color: "#040509",
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            m="15px"
                        >
                            <img src={logo} alt='logo' />
                        </Box>
                    </MenuItem>

                    <Box>
                        <Item
                            title="Tableau de bord"
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {user?.role !== "Platfom administrator" &&
                            <>
                                <Typography
                                    variant="h6"
                                    color="#0c101b"
                                    sx={{ m: "15px 0 5px 20px" }}
                                >
                                    DOSSIERS MÉDICAUX
                                </Typography>
                                <Item
                                    title="Dossiers médicaux encours"
                                    icon={<CreateNewFolderIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Dossiers médicaux archivés"
                                    icon={<FormatListNumberedRtlIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Hospitalisations"
                                    icon={<LocalHotelIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Typography
                                    variant="h6"
                                    color="#0c101b"
                                    sx={{ m: "15px 0 5px 20px" }}
                                >
                                    RENDEZ-VOUS
                                </Typography>


                                {user?.role === 'Medical secretary' ?
                                    <Item
                                        title="Rendez-vous de l'équipe"
                                        icon={<EventNoteIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    : <Item
                                        title="Mes rendez-vous"
                                        icon={<CalendarMonthIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                }
                            </>
                        }

                        <Typography
                            variant="h6"
                            color="#0c101b"
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            FACTURATION
                        </Typography>
                        <Item
                            title="Espace facturation"
                            icon={<ReceiptIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {user?.role === 'Administrator' &&
                            <>
                                <Typography
                                    variant="h6"
                                    color="#0c101b"
                                    sx={{ m: "15px 0 5px 20px" }}
                                >
                                    PERSONNEL DE SANTÉ
                                </Typography>
                                <Item
                                    title="Liste des médecins"
                                    to="/bar"
                                    icon={<SupervisorAccountIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        }
                        {user?.role === "Platfom administrator" &&
                            <>
                                <Typography
                                    variant="h6"
                                    color="#0c101b"
                                    sx={{ m: "15px 0 5px 20px" }}
                                >
                                    ADMIN PLATEFORME
                                </Typography>

                                <Item
                                    title="Liste des cliniques"
                                    to="/bar"
                                    icon={<HomeWorkIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Liste des médecins"
                                    to="/bar"
                                    icon={<SupervisorAccountIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        }

                        <Typography
                            variant="h6"
                            color="#0c101b"
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            DISCUSSIONS
                        </Typography>
                        <Item
                            title="Echanger avec un collègue"
                            to=""
                            icon={<ChatBubbleOutlineIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </ProSidebarProvider>
        </Box>
    )
}

export default Sidebar