import Cookies from "universal-cookie";
import {
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,

} from "../actions/authActions";


const loginInitState = {
    user: {},
    isLogged: false,
    loading: false,
}

export const userLoginReducer = (state = loginInitState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return { ...state, loading: true };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
                isLogged: true,
                // VerifycodeSended: true
            };
        case LOGOUT:
            const cookies = new Cookies();
            cookies.remove('refreshtoken');
            return {};

        case LOGIN_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};


