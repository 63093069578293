import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewBilling from './modals/NewBilling';
import BillDetails from './modals/BillDetails';
import { useDispatch, useSelector } from 'react-redux';
import { findBillsByClinic } from '../redux/actions/appActions';
import { dateParserLong } from '../utils/functions';


const billList = [
    { number: "1Z1A2Z", fullName: "Amadou Ndiaye", amount: 1200, date: '22/02/2023', status: 'Payé' },
    { number: "CDSJJJ", fullName: "Jean Paul", amount: 3000, date: '10/03/2023', status: 'Non payé' },
    { number: "098UUY", fullName: "Fatimetou mint Sidi", amount: 4500, date: '22/02/2023', status: 'Payé' },
    { number: "VQFSFS", fullName: "Ahmed Fall", amount: 3200, date: '21/02/2023', status: 'Payé' },
    { number: "1DATSS", fullName: "Samba Soumaré", amount: 10000, date: '22/02/2023', status: 'Payé' },
    { number: "KDHDGG", fullName: "Amadou Ka", amount: 13000, date: '03/02/2023', status: 'Payé' },
    { number: "CXDGTV", fullName: "Ali Seck", amount: 10000, date: '06/02/2023', status: 'Non payé' },
    { number: "JJSHSG", fullName: "Amy Sy", amount: 12000, date: '18/02/2023', status: 'Payé' },
    { number: "NHJHHG", fullName: "Ndeye Astou", amount: 22200, date: '15/02/2023', status: 'Payé' },
];

const Billing = () => {
    const [open, setOpen] = useState(false);
    const [openBillDetail, setOpenBillDetail] = useState(false);
    const [bill, setBill] = useState(null);
    const [filter, setFilter] = useState('');

    const { user } = useSelector(state => state.auth);
    const { bills } = useSelector(state => state.bills);


    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseBillDetail = () => {
        setOpenBillDetail(false);
    };

    const handleOpenBillDetail = (bill) => {
        setOpenBillDetail(true);
        setBill(bill);
    };
    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(findBillsByClinic(user?.clinic));
    }, [dispatch, user])


    return (
        <div className="listContainer">
            <div className="traderTitle">

                <span className='standardTitle'>Facturation</span>

                <FormControl sx={{ mt: 1, width: "300px" }}>
                    <InputLabel id="demo-simple-select-standard-label">Filtrer par</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={filter}
                        onChange={handleChangeFilter}
                        label="Filtrer par"
                        sx={{ width: "100%" }}
                    >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value={"Non payé"}>Payé</MenuItem>
                        <MenuItem value={"Non payé"}>Non payé</MenuItem>
                    </Select>
                </FormControl>

                <Button onClick={() => setOpen(true)} endIcon={<AddCircleIcon />}
                    variant="outlined" color='success'
                >
                    Nouvelle facture
                </Button>
            </div>
            <Box display="flex" flexDirection="column" width="100%" overflow="auto" sx={{ height: "80vh" }}>

                {
                    bills && bills.map(bill => (
                        <Box key={bill.number} display="flex" alignItems="center" justifyContent="space-around" mb={2} p={2}
                            onClick={() => handleOpenBillDetail(bill)}
                            sx={{ borderRadius: 3, backgroundColor: '#e6e6e6', color: 'black', ":hover": { cursor: 'pointer', backgroundColor: "silver" } }}>
                            <Box display="flex" flex={1}><Typography variant='caption'>{bill.number}</Typography></Box>
                            <Box display="flex" flex={1}><Typography variant='caption'>{bill.owner}</Typography></Box>
                            <Box display="flex" flex={1}><Typography variant='caption'>{dateParserLong(bill.createdAt)}</Typography></Box>
                            <Box display="flex" flex={1}><Typography variant='caption'>{bill.amount} UM</Typography></Box>
                            <Box display="flex" flex={1}>
                                <Typography variant='caption' sx={{ backgroundColor: "seashell", padding: 1, width: 90, textAlign: 'center', borderRadius: 1 }}>
                                    {bill.status === 'Payé' ? <span style={{ color: "green" }}>{bill.status}</span>
                                        : <span style={{ color: "orange" }}>{bill.status}</span>}
                                </Typography>
                            </Box>
                        </Box>
                    ))
                }

            </Box>
            <BillDetails
                open={openBillDetail}
                handleClose={handleCloseBillDetail}
                bill={bill}
            />
            <NewBilling
                open={open}
                handleClose={handleClose}
            />
        </div>
    )
}

export default Billing