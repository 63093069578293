import axios from 'axios';
import Cookies from 'universal-cookie';
import { LOGOUT } from './authActions';

export const CREATE_CONVERSATION_REQUEST = 'CREATE_CONVERSATION_REQUEST';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS';
export const CREATE_CONVERSATION_FAIL = 'CREATE_CONVERSATION_FAIL';

export const FIND_CONVERSATION_REQUEST = 'FIND_CONVERSATION_REQUEST';
export const FIND_CONVERSATION_SUCCESS = 'FIND_CONVERSATION_SUCCESS';
export const FIND_CONVERSATION_FAIL = 'FIND_CONVERSATION_FAIL';

export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAIL = 'CREATE_MESSAGE_FAIL';

export const FIND_MESSAGES_BY_CONVERSATION_REQUEST = 'FIND_MESSAGES_BY_CONVERSATION_REQUEST';
export const FIND_MESSAGES_BY_CONVERSATION_SUCCESS = 'FIND_MESSAGES_BY_CONVERSATION_SUCCESS';
export const FIND_MESSAGES_BY_CONVERSATION_FAIL = 'FIND_MESSAGES_BY_CONVERSATION_FAIL';

export const createNewConversation = (data) => async (dispatch) => {
    dispatch({ type: CREATE_CONVERSATION_REQUEST, payload: data });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/chat/create-conversation`, body, config, { withCredentials: true })

        dispatch({ type: CREATE_CONVERSATION_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: CREATE_CONVERSATION_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const findConversation = (conversation) => async (dispatch) => {
    dispatch({ type: FIND_CONVERSATION_REQUEST, payload: conversation });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/chat/find-single-conversation/${conversation}`, config, { withCredentials: true })

        dispatch({ type: FIND_CONVERSATION_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: FIND_CONVERSATION_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const createNewMessage = (data) => async (dispatch) => {
    dispatch({ type: CREATE_MESSAGE_REQUEST, payload: data });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    const body = JSON.stringify(data)

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/chat/create-message`, body, config, { withCredentials: true })

        dispatch({ type: CREATE_MESSAGE_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: CREATE_MESSAGE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const findMessagesByConversation = (conversation) => async (dispatch) => {
    dispatch({ type: FIND_MESSAGES_BY_CONVERSATION_REQUEST, payload: conversation });
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/chat/find-messages-by-conversation/${conversation}`, config, { withCredentials: true })

        dispatch({ type: FIND_MESSAGES_BY_CONVERSATION_SUCCESS, payload: data.data });

    }
    catch (error) {
        if (error?.message === "Request failed with status code 403") {
            dispatch({ type: LOGOUT });
        }

        dispatch({
            type: FIND_MESSAGES_BY_CONVERSATION_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};