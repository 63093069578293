import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent, findEventByClinical, findEventByProfessional } from '../../redux/actions/appActions';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'dayjs/locale/fr';
import Calendar from '../shared/Calendar';
import { Box } from '@mui/system';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { Divider } from '@mui/material';

export default function NewEvent({ open, handleClose, folder, pro }) {
  const [title, setTitle] = useState('');
  const [start, setStart] = useState(dayjs(new Date()));
  const [end, setEnd] = useState(dayjs(new Date()));

  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const handleStartChange = (newValue) => {
    setStart(newValue);
  };

  const handleEndChange = (newValue) => {
    setEnd(newValue);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    dispatch(createEvent({
      title: title,
      start,
      end,
      clinic: user.clinic,
      professional: user?.role === "Medical secretary" ? pro : user.id,
      folder: folder
    }
    ));

    const dateStart = moment(new Date()).format('DD/MM/YYYY HH:mm');;
    const dateEnd = dateStart.add(5, 'days').format('DD/MM/YYYY HH:mm');

    if (user?.role === "Medical secretary") {
      dispatch(findEventByClinical({
        "start": dateStart,
        "end": dateEnd,
        "clinic": user.clinic
      }));

    } else {
      dispatch(findEventByProfessional({
        "start": dateStart,
        "end": dateEnd,
        "professional": user.id
      }));

    }

    handleClose();

  }

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle sx={{ backgroundColor: "#595959", color: "white", fontSize: "14px" }}>CRÉATION D'UN NOUVEAU RENDEZ-VOUS</DialogTitle>
      <DialogContent>
        <form onSubmit={handleRegister}>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ backgroundColor: 'whitesmoke' }}>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Titre"
              type="text"
              fullWidth
              multiline
              rows={2}
              onChange={e => setTitle(e.target.value)}
            />

            <FormControl variant="standard" sx={{ mr: 1, ml: 2, width: "100%" }}>

              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="DATE DÉBUT"
                  value={start}
                  onChange={handleStartChange}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl variant="standard" sx={{ ml: 1, mr: 2, width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="DATE FIN"
                  value={end}
                  onChange={handleEndChange}
                />
              </LocalizationProvider>
            </FormControl>

            <DialogActions>
              <Button variant="contained" type='submit' endIcon={<DoneIcon />} onClick={handleRegister} sx={{ backgroundColor: 'black' }}>Valider</Button>
              <Button variant="outlined" onClick={handleClose} endIcon={<ClearIcon />} >Annuler</Button>
            </DialogActions>
          </Box>
        </form>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Calendar />

      </DialogContent>

    </Dialog>
  );
}