import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { Button, Stack, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { findAllClinics, updateClinic } from '../../redux/actions/appActions';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NewProfessional from './NewProfessional';
import Nofications from '../shared/Nofications';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClinicDetails({ open, handleClose }) {
    const { selectedClinic, updatedClinic } = useSelector(state => state.clinics);
    const [openNewProf, setOpenNewProf] = React.useState(false);

    const [openNotif, setOpenNotif] = React.useState(false);

    const [name, setName] = React.useState(selectedClinic?.name);
    const [domain, setDomain] = React.useState(selectedClinic?.domain);
    const [description, setDescription] = React.useState(selectedClinic?.description);
    const [city, setCity] = React.useState(selectedClinic?.city);
    const [address, setAddress] = React.useState(selectedClinic?.address);
    const [phone, setPhone] = React.useState(selectedClinic?.phone);
    const [email, setEmail] = React.useState(selectedClinic?.email);

    const [editMode, setEditMode] = React.useState(false);

    const { success, error } = useSelector(state => state.clinics);

    const dispatch = useDispatch();

    const handleCloseNewProf = () => {
        setOpenNewProf(false);
    };

    const handleCloseNotif = () => {
        setOpenNotif(false);
        dispatch({ type: 'CLEAN_CLINIC_MESSAGES' });
    };

    const handleUpdate = (e) => {
        e.preventDefault();

        dispatch(updateClinic(selectedClinic?.id, { name, domain, phone, description, city, email, address }));
        setEditMode(false)
    }

    React.useEffect(() => {
        if (success || error) {
            setOpenNotif(true)
        }
    }, [success, error])

    React.useEffect(() => {
        if (updatedClinic && updatedClinic?.id) {
            dispatch({ type: 'SELECTED_CLINIC', payload: updatedClinic });
        }

    }, [updatedClinic, dispatch]);




    const handleDesactvateAccount = () => {
        const newStatus = selectedClinic?.status === "Actif" ? "Inactif" : "Actif"
        dispatch(updateClinic(selectedClinic?.id, { status: newStatus }));
        dispatch(findAllClinics());
        setEditMode(false)
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#e6e6e6', color: 'black' }}>
                <Toolbar>
                    {selectedClinic?.status === 'Actif' ? <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase', color: 'green', }} variant="body2" component="div">
                        {selectedClinic?.name} (ouvert)
                    </Typography> :
                        <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase', color: 'orangered', }} variant="body2" component="div">
                            {selectedClinic?.name} (archivé)
                        </Typography>
                    }
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box m={1} display="flex" justifyContent="space-between">
                {selectedClinic?.status === 'Actif' ?
                    <>

                        <Button variant="contained" endIcon={<EditIcon />} onClick={() => setEditMode(true)} sx={{ backgroundColor: 'black' }}>
                            Modifier les informations
                        </Button>
                        <Button variant="contained" endIcon={<PersonAddIcon />} onClick={() => setOpenNewProf(true)} sx={{ backgroundColor: 'black' }}>
                            Ajouter un responsable à ce clinique
                        </Button>
                    </> :
                    <Button variant="outlined" sx={{ borderColor: 'black', color: 'black' }} endIcon={<PlayCircleOutlineIcon color='success' />} onClick={handleDesactvateAccount}>
                        Activer cet établissement
                    </Button>
                }

            </Box>

            <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginLeft: 1, width: "99%", backgroundColor: "#595959" }}>
                <Typography sx={{ paddingBottom: 2, color: "white", paddingTop: 2, textTransform: 'uppercase' }} variant="body2" component="div">
                    Informations détaillées de l'établissment
                </Typography>
            </Box>

            <List sx={{ height: "minContent" }}>
                <ListItem >

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="name"
                                    label="Nom"
                                    name="Nom du clinique"
                                    autoComplete="name"
                                    autoFocus
                                    defaultValue={selectedClinic?.name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }}
                                primary="NOM COMPLET DU PATIENT" secondary={selectedClinic?.name} />
                    }
                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="domain"
                                    label="Spécialités"
                                    name="domain"
                                    autoComplete="domain"
                                    autoFocus
                                    defaultValue={selectedClinic?.domain}
                                    onChange={e => setDomain(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }}
                                primary="SPÉCIALITÉS" secondary={selectedClinic?.name} />

                    }


                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="description"
                                    label="Description"
                                    name="description"
                                    autoComplete="description"
                                    autoFocus
                                    defaultValue={selectedClinic?.description}
                                    onChange={e => setDescription(e.target.value)}
                                    multiline
                                    rows={2}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="DESCRIPTION" secondary={selectedClinic?.description ? selectedClinic?.description : "--"} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="city"
                                    label="Ville"
                                    name="city"
                                    autoComplete="city"
                                    autoFocus
                                    defaultValue={selectedClinic?.city !== '' ? selectedClinic?.city : 'NA'}
                                    onChange={e => setCity(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="VILLE DE NAISSANCE" secondary={selectedClinic?.city ? selectedClinic?.city : '--'} />
                    }


                </ListItem>
                <Divider />

                <ListItem >
                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="phone"
                                    label="Téléphone"
                                    name="phone"
                                    autoComplete="phone"
                                    autoFocus
                                    defaultValue={selectedClinic?.phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="TÉLÉPHONE" secondary={selectedClinic?.phone ? selectedClinic?.phone : '--'} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="email"
                                    label="EMAIL"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    defaultValue={selectedClinic?.email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="EMAIL" secondary={selectedClinic?.email ? selectedClinic?.email : '--'} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="address"
                                    label="Adresse"
                                    name="address"
                                    autoComplete="address"
                                    autoFocus
                                    defaultValue={selectedClinic?.address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="ADRESSE" secondary={selectedClinic?.address ? selectedClinic?.address : '--'} />
                    }

                    <ListItemText sx={{ width: "20%" }} primary="STATU" secondary={selectedClinic?.status ? selectedClinic?.status : '--'} />




                </ListItem>

                <Divider />


                <Divider />
            </List>
            <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                <Box display="flex" justifyContent="center" >
                    <Stack direction="row" spacing={2}>
                        {editMode &&
                            <Stack direction="row" spacing={50}>
                                <Stack direction="row" spacing={3}>
                                    <Button variant="outlined" endIcon={<ClearIcon />} onClick={() => setEditMode(false)} sx={{ borderColor: 'black', color: 'black' }}>
                                        Annuler les modifications
                                    </Button>
                                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleUpdate} sx={{ backgroundColor: 'black' }}>
                                        Valider les modifications
                                    </Button>
                                </Stack>

                                {selectedClinic?.status === 'Actif' &&
                                    <Button variant="outlined" color="error" endIcon={<StopCircleIcon />} onClick={handleDesactvateAccount}>
                                        Clôturer et archiver ce dossier
                                    </Button>
                                }

                            </Stack>


                        }
                    </Stack>
                </Box>
            </Box>
            <NewProfessional
                open={openNewProf}
                handleClose={handleCloseNewProf}
            />
            <Nofications
                open={openNotif}
                handleClose={handleCloseNotif}
                transition="TransitionLeft"
                message={success ? 'Les informations ont mises à jour avec succés' :
                    error ? "Erreur lors de la mise à jour des informations du clinique"
                        : "Erreur technique lors de la mise à jour des informations du clinique"
                }
                severity={success ? 'success' : 'error'}
            />

        </Dialog>
    );
}