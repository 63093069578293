import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect } from 'react'
import Calendar from './shared/Calendar'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewEvent from './modals/NewEvent';
import { useDispatch, useSelector } from 'react-redux';
import { findFoldersByProfessional, findProfessionalsByClinic } from '../redux/actions/appActions';
import { dateParserShort } from '../utils/functions';

const Appointments = ({ target }) => {
    const [openEvent, setOpenEvent] = React.useState(false);
    const [pro, setPro] = React.useState('');
    const [folder, setFolder] = React.useState('');

    const { user } = useSelector(state => state.auth);
    const { professionals } = useSelector(state => state.professionals);
    const { folders } = useSelector(state => state.folders);

    const dispatch = useDispatch();

    const handleCloseEvent = () => {
        setOpenEvent(false);
    };

    const handleChangePro = (event) => {
        setPro(event.target.value);
    };

    const handleChangeFolder = (event) => {
        setFolder(event.target.value);
    };

    const handleOpenNewEvent = () => {
        if (folder !== '' && pro !== '') {
            setOpenEvent(true);
        }
    };

    useEffect(() => {
        if (user?.role === 'Medical secretary') {
            dispatch(findProfessionalsByClinic(user?.clinic));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (pro !== '') {
            dispatch(findFoldersByProfessional(pro));
        }
    }, [dispatch, pro])

    return (
        <Box  >
            <NewEvent
                open={openEvent}
                handleClose={handleCloseEvent}
                folder={folder}
                pro={pro}
            />
            <div className="listContainer">
                <div className="traderTitle">
                    <span className='standardTitle'>{target === 'ME' ? 'Mes rendez-vous' : target === 'TEAM' ? "Les rendez-vous de l'équipe" : ''}</span>
                    {
                        target === 'TEAM' &&
                        <Box display="flex" width="400px">
                            <FormControl sx={{ mt: 1, width: "100%", paddingRight: 2 }}>
                                <InputLabel id="demo-simple-select-standard-label">Médecin</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={pro}
                                    onChange={handleChangePro}
                                    label="Type de consultation"
                                    sx={{ width: "100%" }}
                                >
                                    <MenuItem value="">
                                        <em></em>
                                    </MenuItem>
                                    {
                                        professionals && professionals.map(prof => (
                                            <MenuItem value={prof.id}>{'Dr ' + prof?.firstName + ' ' + prof?.lastName}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <InputLabel id="demo-simple-select-standard-label">Patient</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={folder}
                                    onChange={handleChangeFolder}
                                    label="Type de consultation"
                                    sx={{ width: "100%" }}
                                >
                                    <MenuItem value="">
                                        <em></em>
                                    </MenuItem >
                                    {folders && folders.map(fold => (
                                        <MenuItem
                                            value={fold.id}
                                            sx={{ fontSize: "14px" }}
                                        >
                                            {fold?.patientFirstname + ' ' + fold?.patientLastname + ' - Né(e) le : ' + dateParserShort(fold?.patientBirthday) + ' - Tel: ' + fold?.patientPhone + ' - N° Dossier: ' + (fold?.number ? fold?.number : fold?.id)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    }

                    {
                        target === 'TEAM' && <AddCircleIcon
                            className='iconButton'
                            color="success"
                            onClick={handleOpenNewEvent}
                        />
                    }
                </div>
                <Calendar />
            </div>
        </Box>
    )
}

export default Appointments