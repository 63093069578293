import { Box, Fab, FormControl, IconButton, InputAdornment, List, ListItem, ListItemButton, OutlinedInput, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { findProfessionalsByClinic } from '../redux/actions/appActions';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import ThreePIcon from '@mui/icons-material/ThreeP';
import ChatIcon from '@mui/icons-material/Chat';
import { createNewMessage, findConversation, findMessagesByConversation } from '../redux/actions/chatActions';
import { io } from "socket.io-client";
import ReactTimeAgo from 'react-time-ago';



const Chat = () => {
    const [openChat, setOpenChat] = useState(true);
    const [targetUser, setTargetUser] = useState(null);
    const [message, setMessage] = useState("");
    const [sendMessage, setSendMessage] = useState(null);
    // const [receiveMessage, setReceiveMessage] = useState(null);
    // const [onLineProfessionals, setOnLineProfessionals] = useState([]);


    const { user } = useSelector(state => state.auth);
    const { professionals } = useSelector(state => state.professionals);
    const { conversation, conversationMessages, newMessage } = useSelector(state => state.chat);

    const socket = useRef();
    const scroll = useRef()

    const dispatch = useDispatch();

    // CONNECTION TO SOCKET SERVER 
    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_CHAT_SERVER_URL}`);
        // socket.current = io("ws://localhost:5001");

        socket.current.emit("new-professional-add", user.id);
        socket.current.on('get-professionals', (professionals) => {
            // setOnLineProfessionals(professionals);
        })
    }, [user]);

    // SEND MESSAGE TO SOCKET SERVER
    useEffect(() => {
        if (sendMessage) {
            socket.current.emit('send-message', sendMessage);
            handleFindConversationMessages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendMessage]);

    // RECEIVE MESSAGE TO SOCKET SERVER
    useEffect(() => {
        socket.current.on('receive-message', (data) => {
            console.log("receive-message: ", data)
            handleFindConversationMessages();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessage]);

    useEffect(() => {
        dispatch(findProfessionalsByClinic(user?.clinic));
    }, [dispatch, user]);

    useEffect(() => {
        if (conversation && conversation.id) {
            dispatch(findMessagesByConversation(conversation?.id))
        }
    }, [conversation, dispatch]);

    const handleOpenChat = (prof) => {
        setOpenChat(true);
        setTargetUser(prof);
        initialzeChat(prof);

    }

    const handleCloseChat = () => {
        setOpenChat(false);
        setTargetUser(null)
    }

    const initialzeChat = (prof) => {
        dispatch(findConversation(user?.id + "___" + prof?.id + "___" + user?.clinic));
    }

    const handleAddNewMessage = (e) => {
        e.preventDefault();
        if (message !== '' && conversation) {
            dispatch(createNewMessage({ conversation: conversation?.id, sender: user?.id, message, receiver: targetUser?.id }))
            setMessage('');
            handleFindConversationMessages();
            setSendMessage({ conversation: conversation?.id, sender: user?.id, message, receiver: targetUser?.id })

            // send message to socket server
            socket.current.emit('send-message', { conversation: conversation?.id, sender: user?.id, message, receiver: targetUser?.id });
            handleFindConversationMessages();

        }

    }

    const handleFindConversationMessages = () => {
        if (message !== '' && conversation) {
            dispatch(findMessagesByConversation(conversation?.id))
        }
    }

    useEffect(() => {
        // socket.current = io(`${process.env.REACT_APP_CHAT_SERVER_URL}`);
        socket.current.on("getMessage", (data) => {
            // setArrivalMessage({
            //     sender: data.senderId,
            //     text: data.text,
            //     createdAt: Date.now(),
            // });
        });
    }, []);

    // Always scroll to the last message
    useEffect(() => {
        scroll.current?.scrollIntoView({ behavior: "smooth" });
    }, [conversationMessages]);

    return (
        <Box display="flex"  >

            <Box flex={1} display="flex" flexDirection="column" p={2}>
                <Typography>Liste de mes collègues</Typography>
                <Box flex={1} display="flex" flexDirection="column" p={2} height="100vh" sx={{ overflowX: "hidden" }} overflow="auto" >
                    <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {professionals && professionals?.filter(item => item?.id !== user?.id)
                            .map((prof) => {
                                // const labelId = `checkbox-list-secondary-label-${prof?.firstName}`;
                                return (
                                    <ListItem key={prof?.id}>
                                        <ListItemButton onClick={() => handleOpenChat(prof)}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={`Avatar`}
                                                    src={`/static/images/avatar/avatar.jpg`}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText id={prof.id} primary={prof.firstName + ' ' + prof.lastName} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                    </List>

                </Box>
            </Box>
            <Box position={"relative"} flex={2} display="flex" flexDirection="column" height="91vh">
                {openChat ?
                    <Box display="flex" flexDirection="column" position={"fixed"} bottom={2} right={10} borderRadius="12px 12px 0 0"
                        sx={{ borderColor: "silver", borderStyle: 'solid', borderWidth: "1px" }} width="53%" height="90.5vh">
                        <Box display="flex" justifyContent="space-between" alignItems="center" p={2} sx={{ backgroundColor: "black", color: "white" }}>
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                <ThreePIcon />
                                {
                                    targetUser && <Typography ml={1} variant="caption"> {targetUser?.firstName + ' ' + targetUser?.lastName}</Typography>
                                }
                            </Box>
                            <CloseIcon onClick={handleCloseChat} />
                        </Box>
                        <Box height="86vh" p={2} display="flex" flexDirection="column" sx={{ overflowX: "hidden" }} overflow="auto" >
                            {
                                conversationMessages && conversationMessages.map(mess => (
                                    <Box key={mess?.id} display="flex" flexDirection="column" alignItems={user?.id === mess?.sender ? "flex-end" : "flex-start"} width="100%" mb={0.5} p={0.5} borderRadius="6px">
                                        <Box ref={scroll} display="flex" p={1} flexDirection="column" borderRadius="8px" alignItems={user?.id === mess?.sender ? "flex-end" : "flex-start"} color={user?.id === mess?.sender ? "#000000" : "#ffffff"} bgcolor={user?.id === mess?.sender ? "#e6e6e6" : "#4d4d4d"}>
                                            <Typography variant='caption' key={mess} >{mess?.message}</Typography>
                                            <ReactTimeAgo style={{ "paddingLeft": "1px", paddingRight: 1, }} date={Date.parse(mess?.createdAt)} locale="fr" />
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Box>
                        <Box height="7vh" display="flex" alignItems="center" justifyContent="space-between" mb={0}>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type='text'
                                    onChange={e => setMessage(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle"
                                                onClick={handleAddNewMessage}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleAddNewMessage();
                                                    }
                                                }}
                                                edge="end"
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="message"
                                    // multiline
                                    // rows={2}
                                    value={message}
                                    placeholder="Taper votre message ..."
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    : <Box position={"fixed"} bottom={2} right={10} >
                        <Fab color="primary" aria-label="add" onClick={() => setOpenChat(true)}>
                            <ChatIcon size={50} />
                        </Fab>
                    </Box>
                }

            </Box>
        </Box >
    )
}

export default Chat