import React, { useEffect } from 'react'
import './customTable.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { findFoldersByProfessional, findSheetsById } from '../../../redux/actions/appActions';
import { dateParserShort } from '../../../utils/functions';
import SheetDetails from '../../modals/SheetDetails';
import { Box, Button, FormControl, MenuItem, Pagination, Select, Stack } from '@mui/material';


const SheetsTable = () => {
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(5);

    const { user } = useSelector(state => state.auth);
    const { selectedFolder, sheets, sheetTotalPages } = useSelector(state => state.folders);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(findFoldersByProfessional(user?.id));
    }, [dispatch, user])

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
    };

    useEffect(() => {
        dispatch(findSheetsById(selectedFolder?.id, page, limit));
    }, [dispatch, selectedFolder, page, limit, sheetTotalPages])

    const handleClose = () => {
        setOpen(false);
    };

    const handleDisplaySheetInfo = (sheet) => {
        setOpen(true)
        dispatch({ type: "SELECTED_SHEET", payload: sheet });
    }

    return <>
        <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='tableCell'>CONSULTATION N°</TableCell>
                        <TableCell className="tableCell">NOTES ET SOINS</TableCell>
                        <TableCell className="tableCell">EXAMENS COMPL.</TableCell>
                        <TableCell className="tableCell">DIAGNOSTIC ÉVOQUÉ.</TableCell>
                        <TableCell className="tableCell">ORDONNANCE</TableCell>
                        <TableCell className="tableCell">DATE</TableCell>
                        <TableCell className="tableCell">ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {sheets && sheets.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell className="tableCell">{row.folder ? row.folder : '--'}</TableCell>
                            <TableCell className="tableCell">{row.description ? row.description : '--'}</TableCell>
                            <TableCell className="tableCell">{row.complementaryExams ? <span style={{ color: "green" }}>Oui</span> : <span style={{ color: "red" }}>Pas d'examens complémentaires</span>}</TableCell>
                            <TableCell className="tableCell">{row.diagnosisEvoked ? <span style={{ color: "green" }}>Oui</span> : <span style={{ color: "red" }}>Pas de diagnostic</span>}</TableCell>
                            <TableCell className="tableCell">{row.prescription ? <span style={{ color: "green" }}>Oui</span> : <span style={{ color: "red" }}>Pas d'ordonnance</span>}</TableCell>
                            <TableCell className="tableCell">{dateParserShort(row.createdAt)}</TableCell>
                            <TableCell className="tableCell">
                                <Button onClick={() => handleDisplaySheetInfo(row)} endIcon={<InfoIcon />}
                                    variant="outlined" color='success'
                                >
                                    Détail
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
                <FormControl sx={{ padding: 1 }}>
                    <Select
                        value={limit}
                        onChange={handleChangeLimit}
                        label="Type de consultation"
                        sx={{ width: "100%" }}
                    >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
                <Stack spacing={2}>
                    <Pagination count={sheetTotalPages ? sheetTotalPages : 0} page={page} onChange={handleChange} />
                </Stack>
            </Box>
        </TableContainer>
        <SheetDetails
            open={open}
            handleClose={handleClose}
        />
    </>

}

export default SheetsTable