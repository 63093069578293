import React from 'react'
import { Box, IconButton, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/authActions'
import { findFoldersByClinic, findFoldersByProfessional, searchFoldersByCriteria, setCurrentComponent } from '../../redux/actions/appActions'
import { useState } from 'react'

const Topbar = () => {
    const [isSearched, setIsSearched] = useState(false);
    const [criteria, setCriteria] = useState("");
    const { currentComponent } = useSelector(state => state.app);
    const { user } = useSelector(state => state.auth);
    const { clinical } = useSelector(state => state.clinics);

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout())
    }

    const handleSearch = () => {
        if (criteria) {
            dispatch(searchFoldersByCriteria(criteria, user?.clinic));
            setIsSearched(true);
        }
    }

    const handleResetSearch = () => {
        setIsSearched(false);
        if (user?.role === "Medical secretary") {
            dispatch(findFoldersByClinic(user?.clinic));
        } else {
            dispatch(findFoldersByProfessional(user?.id));
        }
        setCriteria('');
    }
    //#e6e6e6" 
    return (
        <Box display="flex" justifyContent="space-between" p={2} backgroundColor="#e6e6e6" sx={{ BorderBottom: " 2px solid grey", height: "5vh" }}>

            {
                (currentComponent === "Dossiers médicaux encours" || currentComponent === "Dossiers médicaux archivés") ?
                    <Box display="flex" backgroundColor="white" borderRadius="3px" width="500px">
                        <InputBase
                            sx={{ ml: 2, flex: 1 }}
                            placeholder="N° dossier, téléphone, nom ou prénom"
                            onChange={event => setCriteria(event.target.value)}
                            value={criteria}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                        {
                            isSearched ?
                                <IconButton type='button' sx={{ p: 1 }} onClick={handleResetSearch}>
                                    <CloseIcon />
                                </IconButton>
                                :
                                <IconButton type='button' sx={{ p: 1 }} onClick={handleSearch} >
                                    <SearchIcon />
                                </IconButton>
                        }
                    </Box>
                    :
                    <Box></Box>
            }

            {/** ICONS */}
            <Box display="flex" alignItems="center">

                <Box mr={4}>
                    {user?.firstName?.toUpperCase() + ' ' + user?.lastName?.toUpperCase()} ({user?.jobTitle?.toUpperCase()}) {' - ' + clinical?.name?.toUpperCase()}
                </Box>
                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>

                <IconButton onClick={() => dispatch(setCurrentComponent("Mon profil"))}>
                    <PersonOutlinedIcon />
                </IconButton>

                <IconButton onClick={handleLogout}>
                    <LogoutIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default Topbar