import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useDispatch, useSelector } from 'react-redux';
import { calculateAge, dateParserShort } from '../../utils/functions';
import { Box } from '@mui/system';
import { Button, Stack, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { findFolderById, findSheetsById, updateFolder } from '../../redux/actions/appActions';
import SheetsTable from '../shared/table/SheetsTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import NewSheet from './NewSheet';
import NewEvent from './NewEvent';
import ClearIcon from '@mui/icons-material/Clear';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FolderDetails({ open, handleClose }) {
    const [openSheet, setOpenSheet] = React.useState(false);
    const [openEvent, setOpenEvent] = React.useState(false);

    const { folder, selectedFolder, newSheet, updatedFolder } = useSelector(state => state.folders);

    const [patientFirstname, setPatientFirstname] = React.useState(folder?.patientFirstname);
    const [patientLastname, setPatientLastname] = React.useState(folder?.patientLastname);
    const [patientBirthday, setPatientBirthday] = React.useState(folder?.patientBirthday);
    const [patientCity, setPatientCity] = React.useState(folder?.patientCity);
    const [jobTitle, setJobTitle] = React.useState(folder?.jobTitle);
    const [patientPhone, setPatientPhone] = React.useState(folder?.patientPhone);
    const [patientEmail, setPatientEmail] = React.useState(folder?.patientEmail);
    const [patientBloodGroup, setPatientBloodGroup] = React.useState(folder?.patientBloodGroup);
    const [comment, setComment] = React.useState(folder?.comment);

    const [editMode, setEditMode] = React.useState(false);

    const dispatch = useDispatch();

    const handleUpdate = (e) => {
        e.preventDefault();

        dispatch(updateFolder(folder?.id, { patientFirstname, patientLastname, patientPhone, patientBirthday, patientBloodGroup, comment, jobTitle, patientCity, patientEmail }));
        setEditMode(false)
    }

    const handleDesactvateAccount = () => {
        const newStatus = folder?.status === "Actif" ? "Archivé" : "Actif"
        dispatch(updateFolder(folder?.id, { status: newStatus }));
        setEditMode(false)
    }

    React.useEffect(() => {
        if (selectedFolder && selectedFolder?.id) {
            dispatch(findFolderById(selectedFolder?.id));
        }
    }, [selectedFolder, dispatch])

    React.useEffect(() => {
        if (selectedFolder && selectedFolder?.id) {
            dispatch(findSheetsById(selectedFolder?.id));
        }
    }, [selectedFolder, dispatch]);

    React.useEffect(() => {
        if (updatedFolder && updatedFolder?.id) {
            dispatch(findFolderById(updatedFolder?.id));
        }

    }, [updatedFolder, dispatch]);

    const handleCloseSheet = () => {
        setOpenSheet(false);
    };

    const handleCloseEvent = () => {
        setOpenEvent(false);
    };

    React.useEffect(() => {
        if (newSheet || (folder && folder?.id)) {
            dispatch(findSheetsById(folder?.id, 1, 5));
        }
    }, [dispatch, folder, newSheet])


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#e6e6e6', color: 'black' }}>
                <Toolbar>
                    {folder?.status === 'Actif' ? <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase', color: 'green', }} variant="body2" component="div">
                        N° de Dossier médical: {folder?.id} (ouvert)
                    </Typography> :
                        <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase', color: 'orangered', }} variant="body2" component="div">
                            N° de Dossier médical: {folder?.id} (archivé)
                        </Typography>
                    }
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box m={1} display="flex" justifyContent="space-between">
                {folder?.status === 'Actif' ?
                    <>
                        <Button color='success' variant="outlined" endIcon={<AlarmAddIcon />} onClick={() => setOpenEvent(true)}>
                            Nouveau RDV pour ce dossier
                        </Button>
                        <Button color='success' variant="outlined" endIcon={<ScreenShareIcon />} onClick={() => setOpenEvent(true)}>
                            Reaffecter ce dossier
                        </Button>
                        <Button color='success' variant="outlined" endIcon={<ZoomInIcon />} onClick={() => setOpenEvent(true)}>
                            Donner le droit de lecture
                        </Button>
                        <Button color='success' variant="contained" endIcon={<EditIcon />} onClick={() => setEditMode(true)} >
                            Modifier les informations
                        </Button>
                    </> :
                    <Button variant="outlined" endIcon={<PlayCircleOutlineIcon color='success' />} onClick={handleDesactvateAccount}>
                        Rouvrir ce dossier
                    </Button>
                }

            </Box>

            <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginLeft: 1, width: "99%", backgroundColor: "#595959" }}>
                <Typography sx={{ paddingBottom: 2, color: "white", paddingTop: 2, textTransform: 'uppercase' }} variant="body2" component="div">
                    Informations détaillées du dossier médical
                </Typography>
            </Box>

            <List sx={{ height: "minContent" }}>
                <ListItem >

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="patientFirstname"
                                    label="Nom"
                                    name="patientFirstname"
                                    autoComplete="fullName"
                                    autoFocus
                                    defaultValue={folder?.patientFirstname}
                                    onChange={e => setPatientFirstname(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="NOM COMPLET DU PATIENT" secondary={folder?.patientFirstname + ' ' + folder?.patientLastname + " (" + calculateAge(folder?.patientBirthday) + " ans)"} />
                    }
                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="patientLastname"
                                    label="Prénom"
                                    name="patientLastname"
                                    autoComplete="patientLastname"
                                    autoFocus
                                    defaultValue={folder?.patientLastname}
                                    onChange={e => setPatientLastname(e.target.value)}
                                />
                            </ListItemText> : ''

                    }


                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="patientBirthday"
                                    label="Date de naissance"
                                    name="patientBirthday"
                                    autoComplete="patientBirthday"
                                    autoFocus
                                    defaultValue={dateParserShort(folder?.patientBirthday)}
                                    onChange={e => setPatientBirthday(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="DATE DE NAISSANCE" secondary={dateParserShort(folder?.patientBirthday)} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="patientCity"
                                    label="Lieu de naissance"
                                    name="patientCity"
                                    autoComplete="patientCity"
                                    autoFocus
                                    defaultValue={folder?.patientCity !== '' ? folder?.patientCity : 'NA'}
                                    onChange={e => setPatientCity(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="VILLE DE NAISSANCE" secondary={folder?.patientCity ? folder?.patientCity : '--'} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="patientPhone"
                                    label="Téléphone"
                                    name="patientPhone"
                                    autoComplete="patientPhone"
                                    autoFocus
                                    defaultValue={folder?.patientPhone}
                                    onChange={e => setPatientPhone(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="GENRE" secondary={folder?.patientGender ? folder?.patientGender : '--'} />
                    }

                </ListItem>
                <Divider />

                <ListItem >
                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="patientPhone"
                                    label="Téléphone"
                                    name="patientPhone"
                                    autoComplete="patientPhone"
                                    autoFocus
                                    defaultValue={folder?.patientPhone}
                                    onChange={e => setPatientPhone(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="TÉLÉPHONE" secondary={folder?.patientPhone ? folder?.patientPhone : '--'} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="patientEmail"
                                    label="EMAIL"
                                    name="patientEmail"
                                    autoComplete="patientEmail"
                                    autoFocus
                                    defaultValue={folder?.patientEmail}
                                    onChange={e => setPatientEmail(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="EMAIL" secondary={folder?.patientEmail ? folder?.patientEmail : '--'} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="job"
                                    label="TYPE DE SOIN"
                                    name="consulationType"
                                    autoComplete="consulationType"
                                    autoFocus
                                    defaultValue={folder?.consulationType}
                                    onChange={e => setJobTitle(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%" }} primary="TYPE DE SOIN" secondary={folder?.consulationType ? folder?.consulationType : '--'} />
                    }

                    {
                        editMode ?
                            <ListItemText sx={{ width: "20%" }} >
                                <TextField margin="normal"
                                    required
                                    fullWidth={false}
                                    id="patientBloodGroup"
                                    label="Groupe sanguin"
                                    name="patientBloodGroup"
                                    autoComplete="patientBloodGroup"
                                    autoFocus
                                    defaultValue={folder?.patientBloodGroup}
                                    onChange={e => setPatientBloodGroup(e.target.value)}
                                />
                            </ListItemText> :
                            <ListItemText sx={{ width: "20%", alignContent: "flex-start" }} primary="GROUPE SINGUIN" secondary={folder?.patientBloodGroup ? folder?.patientBloodGroup : '--'} />
                    }

                    {
                        editMode &&
                        <ListItemText sx={{ width: "20%" }} >
                            <TextField margin="normal"
                                required
                                fullWidth={false}
                                id="comment"
                                label="NOTE"
                                name="comment"
                                autoComplete="comment"
                                autoFocus
                                defaultValue={folder?.comment}
                                onChange={e => setComment(e.target.value)}
                                multiline
                                rows={2}
                                sx={{ width: "100%" }}
                            />
                        </ListItemText>
                    }

                </ListItem>

                <Divider />

                {folder?.comment &&
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "99%", mb: 1 }}>
                        <ListItem sx={{ width: "900px", display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "center" }} >
                            {
                                !editMode &&
                                <Box display="flex" alignItems="center" justifyContent="center" minWidth="900px">
                                    <Typography sx={{ textTransform: 'uppercase', mr: 1 }} variant="body1" component="div">
                                        NOTES
                                    </Typography>
                                    <ListItemText sx={{ padding: 2, width: "100%", borderColor: 'silver', borderWidth: '1px', borderStyle: 'solid', borderRadius: "6px" }} secondary={folder?.comment} />
                                </Box>
                            }

                        </ListItem>
                    </Box>}
                <Divider />
            </List>
            <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                <Box display="flex" justifyContent="center" >
                    <Stack direction="row" spacing={2}>
                        {editMode &&
                            <Stack direction="row" spacing={50}>
                                <Stack direction="row" spacing={3}>
                                    <Button variant="outlined" endIcon={<ClearIcon />} onClick={() => setEditMode(false)} sx={{ borderColor: 'black', color: 'black' }}>
                                        Annuler les modifications
                                    </Button>
                                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleUpdate} sx={{ backgroundColor: 'black' }}>
                                        Valider les modifications
                                    </Button>
                                </Stack>

                                {folder?.status === 'Actif' &&
                                    <Button variant="outlined" color="error" endIcon={<StopCircleIcon />} onClick={handleDesactvateAccount}>
                                        Clôturer et archiver ce dossier
                                    </Button>
                                }

                            </Stack>


                        }
                    </Stack>
                </Box>
            </Box>

            {folder?.status === 'Actif' &&
                <Box display="flex" justifyContent="flex-end" pr={2} mb={1}>
                    <AddCircleIcon
                        className='iconButton'
                        color="success"
                        onClick={() => setOpenSheet(true)}
                    />
                </Box>
            }
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginLeft: 1, width: "99%", backgroundColor: "#595959" }}>
                <Typography sx={{ paddingBottom: 2, color: "white", paddingTop: 2, textTransform: 'uppercase' }} variant="body2" component="div">
                    Historique des consulations
                </Typography>
            </Box>
            <SheetsTable />
            <NewSheet
                open={openSheet}
                handleClose={handleCloseSheet}
                folder={folder}
            />

            <NewEvent
                open={openEvent}
                handleClose={handleCloseEvent}
                folder={folder?.id}
            />
        </Dialog>
    );
}