import React, { useEffect } from 'react'
import './customTable.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { findAllProfessionals, findProfessionalsByClinic } from '../../../redux/actions/appActions';
import { dateParserShort } from '../../../utils/functions';
import ProfessionalDetails from '../../modals/ProfessionalDetails';
import { Box, Button, FormControl, MenuItem, Pagination, Select, Stack } from '@mui/material';


const AdminTable = () => {
    const [open, setOpen] = React.useState(false);
    const [selectedProfessional, setSelectedProfessional] = React.useState();
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);

    const { user } = useSelector(state => state.auth);
    const { professionals, ProfessionalTotalPages } = useSelector(state => state.professionals);

    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.role === 'Administrator') {
            dispatch(findProfessionalsByClinic(user?.clinic, page, limit));
        }
        else if (user?.role === 'Platfom administrator') {
            dispatch(findAllProfessionals(page, limit));
        }

    }, [dispatch, user, page, limit, ProfessionalTotalPages])

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDisplayAgentInfo = (user) => {
        setOpen(true)
        dispatch({ type: "SELECTED_AGENT", payload: user });
        setSelectedProfessional(user)
    }

    return <>
        <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="tableCell">NOM</TableCell>
                        <TableCell className="tableCell">PRENOM</TableCell>
                        <TableCell className="tableCell">DATE DE NAISSANCE</TableCell>
                        <TableCell className="tableCell">LIEU DE NAISSANCE</TableCell>
                        <TableCell className="tableCell">TÉLÉPHONE</TableCell>
                        <TableCell className="tableCell">POSTE OCCUPÉ(E)</TableCell>
                        <TableCell className="tableCell">STATUS</TableCell>
                        <TableCell className="tableCell">ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        professionals && professionals.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.firstName}</TableCell>
                                <TableCell className="tableCell">{row.lastName}</TableCell>
                                <TableCell className="tableCell">{dateParserShort(row.birthday)}</TableCell>
                                <TableCell className="tableCell">{row.city ? row.city : '--'}</TableCell>
                                <TableCell className="tableCell">{row.phone}</TableCell>
                                <TableCell className="tableCell">{row.jobTitle}</TableCell>
                                <TableCell className="tableCell">
                                    <span className={`status ${row.status}`}>{row.status}</span>
                                </TableCell>
                                <TableCell className="tableCell">
                                    <Button onClick={() => handleDisplayAgentInfo(row)} endIcon={<InfoIcon />}
                                        variant="outlined" color='success'
                                    >
                                        Détail
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
                <FormControl sx={{ padding: 1 }}>
                    <Select
                        value={limit}
                        onChange={handleChangeLimit}
                        label="Type de consultation"
                        sx={{ width: "100%" }}
                    >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
                <Stack spacing={2}>
                    <Pagination count={ProfessionalTotalPages ? ProfessionalTotalPages : 0} page={page} onChange={handleChange} />
                </Stack>
            </Box>
        </TableContainer>
        <ProfessionalDetails
            open={open}
            handleClose={handleClose}
            professional={selectedProfessional}
            setProfessional={setSelectedProfessional}
        />
    </>

}

export default AdminTable