import React, { useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import NewHospitalization from './modals/NewHospitalization';
import HospitalisationDetails from './modals/HospitalisationDetails';

const hospiList = [
    { number: "1Z1A2Z", fullName: "Amadou Ndiaye", startDate: '22/02/2023', endDate: '', status: 'Suivi' },
    { number: "CDSJJJ", fullName: "Jean Paul", startDate: '10/03/2023', endDate: '', status: 'Suivi' },
    { number: "098UUY", fullName: "Fatimetou mint Sidi", startDate: '22/02/2023', endDate: '', status: 'Suivi' },
    { number: "VQFSFS", fullName: "Ahmed Fall", startDate: '21/02/2023', endDate: '', status: 'Suivi' },
    { number: "1DATSS", fullName: "Samba Soumaré", startDate: '22/02/2023', endDate: '', status: 'Suivi' },
    { number: "KDHDGG", fullName: "Amadou Ka", startDate: '03/02/2023', endDate: '', status: 'Suivi' },
    { number: "CXDGTV", fullName: "Ali Seck", startDate: '06/02/2023', endDate: '', status: 'Suivi' },
    { number: "JJSHSG", fullName: "Amy Sy", startDate: '18/02/2023', endDate: '15/03/2023', status: 'Sortie prévue' },
    { number: "NHJHHG", fullName: "Ndeye Astou", startDate: '15/02/2023', endDate: '07/03/2023', status: 'Sorti(e)' },
];

function Hospitalization() {
    const [open, setOpen] = useState(false);
    const [openHospi, setOpenHospi] = useState(false);
    const [hospi, setHospi] = useState(null);
    const [filter, setFilter] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseHospiDetail = () => {
        setOpenHospi(false);
    };

    const handleOpenHospiDetail = (bill) => {
        setOpenHospi(true);
        setHospi(bill);
    };

    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    };


    return (
        <div className="listContainer">
            <div className="traderTitle">

                <span className='standardTitle'>Hospitalisations</span>

                <FormControl sx={{ mt: 1, width: "300px" }}>
                    <InputLabel id="demo-simple-select-standard-label">Filtrer par</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={filter}
                        onChange={handleChangeFilter}
                        label="Filtrer par"
                        sx={{ width: "100%" }}
                    >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value={"Sorti"}>Sorti(e)</MenuItem>
                        <MenuItem value={"Suivi"}>Suivi(e)</MenuItem>
                    </Select>
                </FormControl>

                <Button onClick={() => setOpen(true)} endIcon={<AddCircleIcon />}
                    variant="outlined" color='success'
                >
                    Nouvelle hospitalisation
                </Button>
            </div>
            <Box display="flex" flexDirection="column" width="100%" overflow="auto" sx={{ height: "80vh" }}>

                {
                    hospiList && hospiList.map(hos => (
                        <Box key={hos.number} display="flex" alignItems="center" justifyContent="space-around" mb={2} p={2}
                            onClick={() => handleOpenHospiDetail(hos)}
                            sx={{ borderRadius: 3, backgroundColor: '#e6e6e6', color: 'black', ":hover": { cursor: 'pointer', backgroundColor: "silver" } }}>
                            <Box display="flex" flex={1}><Typography variant='caption'>{hos.number}</Typography></Box>
                            <Box display="flex" flex={1}><Typography variant='caption'>{hos.fullName}</Typography></Box>
                            <Box display="flex" flex={1}><Typography variant='caption'>{'Admis(e) le ' + hos.startDate}</Typography></Box>
                            <Box display="flex" flex={1}><Typography variant='caption'>{hos.endDate ? 'Sorti(e) le ' + hos.endDate : "---------"} </Typography></Box>
                            <Box display="flex" flex={1}><Typography variant='caption' sx={{ backgroundColor: "seashell", padding: 1, width: 90, textAlign: 'center', borderRadius: 1 }}>
                                {hos.status === 'Sorite' ? <span style={{ color: "green" }}>{hos.status}</span>
                                    : hos.status === 'Suivi' ? <span style={{ color: "grey" }}>{hos.status}</span>
                                        : <span style={{ color: "orange" }}>{hos.status}</span>

                                }

                            </Typography>
                            </Box>
                        </Box>
                    ))
                }

            </Box>
            <HospitalisationDetails
                open={openHospi}
                handleClose={handleCloseHospiDetail}
                hospi={hospi}
            />
            <NewHospitalization
                open={open}
                handleClose={handleClose}
            />
        </div>
    )
}

export default Hospitalization;