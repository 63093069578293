import {
    CREATE_CONVERSATION_FAIL,
    CREATE_CONVERSATION_REQUEST,
    CREATE_CONVERSATION_SUCCESS,
    CREATE_MESSAGE_FAIL,
    CREATE_MESSAGE_REQUEST,
    CREATE_MESSAGE_SUCCESS,
    FIND_CONVERSATION_FAIL,
    FIND_CONVERSATION_REQUEST,
    FIND_CONVERSATION_SUCCESS,
    FIND_MESSAGES_BY_CONVERSATION_FAIL,
    FIND_MESSAGES_BY_CONVERSATION_REQUEST,
    FIND_MESSAGES_BY_CONVERSATION_SUCCESS
} from "../actions/chatActions";

export const chatReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CONVERSATION_REQUEST:
            return { ...state, loading: true };
        case CREATE_CONVERSATION_SUCCESS:
            return { ...state, loading: false, newConversation: action.payload };
        case CREATE_CONVERSATION_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_CONVERSATION_REQUEST:
            return { ...state, loading: true };
        case FIND_CONVERSATION_SUCCESS:
            return { ...state, loading: false, conversation: action.payload };
        case FIND_CONVERSATION_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CREATE_MESSAGE_REQUEST:
            return { ...state, loading: true };
        case CREATE_MESSAGE_SUCCESS:
            return { ...state, loading: false, newMessage: action.payload };
        case CREATE_MESSAGE_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_MESSAGES_BY_CONVERSATION_REQUEST:
            return { ...state, loading: true };
        case FIND_MESSAGES_BY_CONVERSATION_SUCCESS:
            return { ...state, loading: false, conversationMessages: action.payload };
        case FIND_MESSAGES_BY_CONVERSATION_FAIL:
            return { ...state, loading: false, error: action.payload };
        case 'CLEAN_NEW_MESSAGE':
            return { ...state, loading: false, success: false, error: "", newMessage: null };
        default:
            return state;
    }
};


