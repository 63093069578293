import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


export function AdminRoute({ children }) {
    const { isLogged } = useSelector(state => state.auth);

    if (!isLogged) {
        return <Navigate to="/login" />
    }

    return children;
}