import axios from 'axios';
import Cookies from 'universal-cookie';
import jwt from 'jwt-decode';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_CODE_VERIFY_SEND_REQUEST = 'REGISTER_CODE_VERIFY_SEND_REQUEST';
export const REGISTER_CODE_VERIFY_SEND_SUCCESS = 'REGISTER_CODE_VERIFY_SEND_SUCCESS';
export const REGISTER_CODE_VERIFY_SEND_FAIL = 'REGISTER_CODE_VERIFY_SEND_FAIL';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAIL = 'USER_INFO_FAIL';
export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';
export const GET_ALL_USERS_BY_ROLE_REQUEST = 'GET_ALL_USERS_BY_ROLE_REQUEST';
export const GET_ALL_USERS_BY_ROLE_SUCCESS = 'GET_ALL_USERS_BY_ROLE_SUCCESS';
export const GET_ALL_USERS_BY_ROLE_FAIL = 'GET_ALL_USERS_BY_ROLE_FAIL';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL';

export const LOGOUT = 'LOGOUT';

export const register = (body) => async (dispatch) => {
    dispatch({ type: REGISTER_REQUEST, payload: body });

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json'
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/register`, body, config, { withCredentials: true })

        dispatch({ type: REGISTER_SUCCESS, payload: data.data });

    }
    catch (error) {
        dispatch({
            type: REGISTER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const login = (body) => async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST, payload: body });
    const cookies = new Cookies();

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json'
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/professionals/login`, body, config, { withCredentials: true })

        if (data?.message === "Success") {
            const decoded = jwt(data.data.token)?.data

            cookies.set('refreshtoken', JSON.stringify(data.data.token), { expires: new Date(decoded.exp * 1000) });

            dispatch({ type: LOGIN_SUCCESS, payload: decoded });
        } else {
            dispatch({
                type: LOGIN_FAIL,
                payload: "Une erreur est servenue lors de ka tentative de connexion. Veillez re essayer"
            })
        }

    }
    catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};


export const updateProfessionalPassword = (password, professionalId) => async (dispatch) => {
    const body = JSON.stringify({ password, defaultPasswordHasChanged: true })

    dispatch({ type: UPDATE_USER_PASSWORD_REQUEST, payload: professionalId });
    const cookies = new Cookies();

    const token = cookies.get('refreshtoken');

    try {
        const config = {
            headers: {
                Accept: "application/json", 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        };



        const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}/professionals/update-password/${professionalId}`, body, config, { withCredentials: true })

        if (data?.message === "Success") {
            dispatch({ type: UPDATE_USER_PASSWORD_SUCCESS, payload: data.data });
        } else {
            dispatch({
                type: UPDATE_USER_PASSWORD_FAIL,
                payload: "Une erreur est servenue lors de ka tentative de connexion. Veillez re essayer"
            })
        }

    }
    catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
};

export const logout = () => (dispatch) => {
    const cookies = new Cookies();
    try {
        cookies.remove('refreshtoken');
        dispatch({ type: LOGOUT })
    } catch (error) {
        console.log('Error')
    }
}

export const getToken = () => async (dispatch) => {
    const cookies = new Cookies();
    const token = cookies.get('refreshtoken');

    if (token && token?.length > 10) {
        const decoded = jwt(token).data
        dispatch({ type: LOGIN_SUCCESS, payload: decoded });
    } else {
        dispatch({ type: LOGOUT })
    }
}

export const setTheme = (mode) => (dispatch) => {
    try {
        dispatch({ type: 'SET_THEME', payload: mode })
    } catch (error) {
        console.log('Error')
    }
}

