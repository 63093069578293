import {
    COUNT_TOTAL_FOLDERS_BY_CLINICAL_REQUEST,
    COUNT_TOTAL_FOLDERS_BY_CLINICAL_SUCCESS,
    COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_FAIL,
    COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_REQUEST,
    COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_SUCCESS,
    TCOUNT_TOTAL_FOLDERS_BY_CLINICAL_FAIL
}
    from "../actions/statsActions";


export const statsReducer = (state = {}, action) => {
    switch (action.type) {
        case COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_REQUEST:
            return { ...state, loading: true };
        case COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_SUCCESS:
            return { ...state, loading: false, totalActifs: action.payload.totalActifs, totalArchives: action.payload.totalArchives };
        case COUNT_TOTAL_FOLDERS_BY_PROFESSIONAL_FAIL:
            return { ...state, loading: false, error: action.payload };
        case COUNT_TOTAL_FOLDERS_BY_CLINICAL_REQUEST:
            return { ...state, loading: true };
        case COUNT_TOTAL_FOLDERS_BY_CLINICAL_SUCCESS:
            return { ...state, loading: false, totalActifs: action.payload.totalActifs, totalArchives: action.payload.totalArchives };
        case TCOUNT_TOTAL_FOLDERS_BY_CLINICAL_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};


