import React from 'react'
import './featured.css'
import "react-circular-progressbar/dist/styles.css";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { CircularProgressbar } from 'react-circular-progressbar';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';


const Featured = ({ balance }) => {
    return (
        <div className='featured'>
            <div className="top">
                <h1 className="title">Dossier médicaux en cours</h1>
                <MoreVertOutlinedIcon fontSize='small' />
            </div>
            <div className="bottom">
                <div className="featuredChart">
                    <CircularProgressbar value={72} text={"72%"} strokeWidth={5} />
                </div>
                {/* <p className="title">Total des ventes aujourd'hui</p> */}
                <p className="amount">{balance} dossiers ouverts</p>
                {/* <p className="desc">Toutes les transactions validées ont été prises en compte.</p> */}
                <div className="summary">
                    <div className="item">
                        <div className="itemTitle">Objectif</div>

                        <div className="itemResult negative">
                            <KeyboardArrowDownOutlinedIcon fontSize='small' />
                            <div className="resultAmount"> 1600 dossiers </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="itemTitle">Semaine dernière</div>
                        <div className="itemResult positive">
                            <KeyboardArrowUpOutlinedIcon fontSize='small' />
                            <div className="resultAmount"> 30 dossiers </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="itemTitle">Mois dernier</div>
                        <div className="itemResult positive">
                            <KeyboardArrowUpOutlinedIcon fontSize='small' />
                            <div className="resultAmount"> 112 dossiers </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Featured