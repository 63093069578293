import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useSelector } from 'react-redux';
import { dateParserShort } from '../../utils/functions';
import { Box } from '@mui/system';
import ReactQuill from 'react-quill';
import { Button } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrescriptionViewer from './PrescriptionViewer';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SheetDetails({ open, handleClose }) {
    const [openPrescriptionViewer, setOpenPrescriptionViewer] = React.useState(false);
    const { selectedSheet } = useSelector(state => state.app);
    const { folder } = useSelector(state => state.folders);


    const handleClosePrescriptionViewer = () => {
        setOpenPrescriptionViewer(false);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#e6e6e6', color: 'black' }}>
                <Toolbar>
                    <Box display="flex" width="100vw" justifyContent="space-between">
                        <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase' }} variant="body2" component="div">
                            N° de Dossier médical: {folder?.id}
                        </Typography>
                        <Typography sx={{ ml: 2, flex: 1, textTransform: 'uppercase' }} variant="body2" component="div">
                            PATIENT : {folder?.patientFirstname + ' ' + folder?.patientLastname}, Né le {dateParserShort(folder?.patientBirthday)} à Nouakchoot
                        </Typography>
                    </Box>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Box display="flex" alignItems="center" justifyContent="center" mt={2} sx={{ marginLeft: 1, width: "99%", backgroundColor: "#595959" }}>
                <Typography sx={{ paddingBottom: 2, color: "white", paddingTop: 2, textTransform: 'uppercase' }} variant="body2" component="div">
                    Informations détaillées de la consultation effectuée le {dateParserShort(selectedSheet?.createdAt)}
                </Typography>
            </Box>

            <List sx={{ height: "minContent" }}>
                <ListItem >
                    <ListItemText sx={{ width: "20%" }} primary="POIDS" secondary={selectedSheet?.weight ? selectedSheet?.weight + ' kg' : '--'} />
                    <ListItemText sx={{ width: "20%" }} primary="TAILLE" secondary={selectedSheet?.size ? selectedSheet?.size : '--'} />
                    <ListItemText sx={{ width: "20%" }} primary="IMC" secondary={selectedSheet?.imc ? selectedSheet?.imc : '--'} />
                    <ListItemText sx={{ width: "20%" }} primary="SATURATION OXYGÈNE" secondary={selectedSheet?.oxygenSaturation ? selectedSheet?.oxygenSaturation : '--'} />
                </ListItem>
                <Divider />
                <ListItem >
                    <ListItemText sx={{ width: "20%" }} primary="TEMPÉRATURE" secondary={selectedSheet?.temperature ? selectedSheet?.temperature + '°' : '--'} />
                    <ListItemText sx={{ width: "20%" }} primary="TENSION" secondary={selectedSheet?.bloodPresure ? selectedSheet?.bloodPresure : '--'} />
                    <ListItemText sx={{ width: "20%" }} primary="FRÉQUENCE CARDIAQUE" secondary={selectedSheet?.cardiacFrequency ? selectedSheet?.cardiacFrequency : '--'} />
                    <ListItemText sx={{ width: "20%" }} primary="FRÉQUENCE RESPIRATOIRE" secondary={selectedSheet?.respiratoryRate ? selectedSheet?.respiratoryRate : '--'} />
                </ListItem>
                <Divider />

                <ListItem sx={{ marginTop: "15px" }}>
                    <Box width="100%" display="flex" flexDirection="column">
                        <Typography>NOTE DES SOINS</Typography>
                        <ListItemText sx={{ width: "95%", borderColor: "silver", borderStyle: "solid", borderWidth: 1, borderRadius: '6px', paddingLeft: 2, paddingRight: 2, paddingBottom: 1, paddingTop: 1, minHeight: '100px' }} secondary={selectedSheet?.description ? selectedSheet?.description : '--'} />
                    </Box>
                    <Box width="100%" display="flex" flexDirection="column">
                        <Typography>EXAMENS COMPLÉMENTAIRES</Typography>
                        <ListItemText sx={{ width: "95%", borderColor: "silver", borderStyle: "solid", borderWidth: 1, borderRadius: '6px', paddingLeft: 2, paddingRight: 2, paddingBottom: 1, paddingTop: 1, Height: '100px', msScrollSnapY: "initial" }} >
                            <ReactQuill
                                value={selectedSheet?.complementaryExams}
                                readOnly={true}
                                theme={"bubble"}
                            />
                        </ListItemText>
                    </Box>
                </ListItem>
                <ListItem mt={5}>
                    <Box width="100%" display="flex" flexDirection="column">
                        <Typography>DIAGNOSTIC ÉVOQUÉ</Typography>
                        <ListItemText sx={{ width: "95%", borderColor: "silver", borderStyle: "solid", borderWidth: 1, borderRadius: '6px', paddingLeft: 2, paddingRight: 2, paddingBottom: 1, paddingTop: 1, Height: '100px', msScrollSnapY: "initial" }} >
                            <ReactQuill
                                value={selectedSheet?.diagnosisEvoked}
                                readOnly={true}
                                theme={"bubble"}
                            />
                        </ListItemText>
                    </Box>
                    <Box width="100%" display="flex" flexDirection="column">
                        <Typography>ORDONNANCE</Typography>
                        <ListItemText sx={{ width: "95%", borderColor: "silver", borderStyle: "solid", borderWidth: 1, borderRadius: '6px', paddingLeft: 2, paddingRight: 2, paddingBottom: 1, paddingTop: 1, Height: '100px', msScrollSnapY: "initial" }} >
                            <ReactQuill
                                value={selectedSheet?.prescription}
                                readOnly={true}
                                theme={"bubble"}
                            />
                        </ListItemText>
                    </Box>
                </ListItem>
                {selectedSheet?.prescription &&
                    <Box width="100%" display="flex" justifyContent="center">
                        <Button variant="outlined" sx={{ borderColor: 'black', color: 'black' }} endIcon={<PictureAsPdfIcon />} onClick={() => setOpenPrescriptionViewer(true)}>
                            Visualiser l'ordonnance au format PDF
                        </Button>
                    </Box>
                }


            </List>

            <PrescriptionViewer
                open={openPrescriptionViewer}
                handleClose={handleClosePrescriptionViewer}
                folder={folder}
                sheet={selectedSheet}
            />
        </Dialog>
    );
}