import React, { useEffect } from 'react'
import { Box } from '@mui/system'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { useDispatch, useSelector } from 'react-redux'
import frLocale from '@fullcalendar/core/locales/fr';
import { findEventByClinical, findEventByProfessional } from '../../redux/actions/appActions'

const Calendar = () => {
    const { events } = useSelector(state => state.events);
    const { user } = useSelector(state => state.auth);
    const [dateStart, setDateStart] = React.useState("");
    const [dateEnd, setDateEnd] = React.useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        if (user && user?.role === "Medical secretary" && dateStart !== "" && dateEnd !== "") {
            dispatch(findEventByClinical({
                "start": dateStart,
                "end": dateEnd,
                "clinic": user.clinic
            }));

        } else if (user && user?.role !== "Medical secretary" && dateStart !== "" && dateEnd !== "") {
            dispatch(findEventByProfessional({
                "start": dateStart,
                "end": dateEnd,
                "professional": user.id
            }));
        }
    }, [dateStart, dateEnd, user, dispatch]);


    const handleDateSet = (date) => {
        setDateStart(date.startStr);
        setDateEnd(date.endStr);
    };

    return (
        <Box >
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                datesSet={(date) => handleDateSet(date)}
                events={events}
                locale={frLocale}
                height={"75vh"}
            />
        </Box>
    )
}

export default Calendar