import {
    CREATE_BILL_FAIL,
    CREATE_BILL_REQUEST,
    CREATE_BILL_SUCCESS,
    CREATE_CLINIC_FAIL,
    CREATE_CLINIC_REQUEST,
    CREATE_CLINIC_SUCCESS,
    CREATE_EVENT_FAIL,
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_SUCCESS,
    CREATE_FOLDER_FAIL,
    CREATE_FOLDER_REQUEST,
    CREATE_FOLDER_SUCCESS,
    CREATE_PROFESSIONAL_FAIL,
    CREATE_PROFESSIONAL_REQUEST,
    CREATE_PROFESSIONAL_SUCCESS,
    CREATE_SHEET_FAIL,
    CREATE_SHEET_REQUEST,
    CREATE_SHEET_SUCCESS,
    FIND_ALL_CLINICS_FAIL,
    FIND_ALL_CLINICS_REQUEST,
    FIND_ALL_CLINICS_SUCCESS,
    FIND_BILL_BY_CLINIC_FAIL,
    FIND_BILL_BY_CLINIC_REQUEST,
    FIND_BILL_BY_CLINIC_SUCCESS,
    FIND_CLINICAL_BY_ID_FAIL,
    FIND_CLINICAL_BY_ID_REQUEST,
    FIND_CLINICAL_BY_ID_SUCCESS,
    FIND_EVENTS_BY_CLINICAL_FAIL,
    FIND_EVENTS_BY_CLINICAL_REQUEST,
    FIND_EVENTS_BY_CLINICAL_SUCCESS,
    FIND_EVENTS_BY_PROFESSIONAL_FAIL,
    FIND_EVENTS_BY_PROFESSIONAL_REQUEST,
    FIND_EVENTS_BY_PROFESSIONAL_SUCCESS,
    FIND_FOLDER_BY_ID_FAIL,
    FIND_FOLDER_BY_ID_REQUEST,
    FIND_FOLDER_BY_ID_SUCCESS,
    FIND_PROFESSIONAL_BY_CLINIC_FAIL,
    FIND_PROFESSIONAL_BY_CLINIC_REQUEST,
    FIND_PROFESSIONAL_BY_CLINIC_SUCCESS,
    FIND_SHEETS_BY_FOLDER_FAIL,
    FIND_SHEETS_BY_FOLDER_REQUEST,
    FIND_SHEETS_BY_FOLDER_SUCCESS,
    GET_ALL_PROFESSIONALS_FAIL,
    GET_ALL_PROFESSIONALS_REQUEST,
    GET_ALL_PROFESSIONALS_SUCCESS,
    GET_FOLDERS_FAIL,
    GET_FOLDERS_REQUEST,
    GET_FOLDERS_SUCCESS,
    SEARCH_FOLDERS_FAIL,
    SEARCH_FOLDERS_REQUEST,
    SEARCH_FOLDERS_SUCCESS,
    SET_COMPONENT,
    UPDATE_CLINIC_FAIL,
    UPDATE_CLINIC_REQUEST,
    UPDATE_CLINIC_SUCCESS,
    UPDATE_FOLDER_FAIL,
    UPDATE_FOLDER_REQUEST,
    UPDATE_FOLDER_SUCCESS,
    UPDATE_PROFESSIONAL_FAIL,
    UPDATE_PROFESSIONAL_REQUEST,
    UPDATE_PROFESSIONAL_SUCCESS,
} from "../actions/appActions";
import { UPDATE_USER_PASSWORD_FAIL, UPDATE_USER_PASSWORD_REQUEST, UPDATE_USER_PASSWORD_SUCCESS } from "../actions/authActions";


export const appReducer = (state = { currentComponent: 'Dashboard' }, action) => {
    switch (action.type) {
        case SET_COMPONENT:
            return { ...state, loading: false, currentComponent: action.payload };
        case 'SET_CUSTOMER-COMPONENT':
            return { ...state, loading: false, customerCurrentComponent: action.payload };
        case 'SELECTED_SHEET':
            return { ...state, loading: false, selectedSheet: action.payload };
        default:
            return state;
    }
};

export const foldersReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FOLDERS_REQUEST:
            return { ...state, loading: true };
        case GET_FOLDERS_SUCCESS:
            return { ...state, loading: false, folders: action.payload.folders, folderTotalPages: action.payload.totalPages };
        case GET_FOLDERS_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CREATE_FOLDER_REQUEST:
            return { ...state, loading: true };
        case CREATE_FOLDER_SUCCESS:
            return { ...state, loading: false, success: true, newFolder: action.payload };
        case CREATE_FOLDER_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_FOLDER_BY_ID_REQUEST:
            return { ...state, loading: true };
        case FIND_FOLDER_BY_ID_SUCCESS:
            return { ...state, loading: false, folder: action.payload };
        case FIND_FOLDER_BY_ID_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_SHEETS_BY_FOLDER_REQUEST:
            return { ...state, loading: true };
        case FIND_SHEETS_BY_FOLDER_SUCCESS:
            return { ...state, loading: false, sheets: action.payload.sheets, sheetTotalPages: action.payload.totalPages };
        case FIND_SHEETS_BY_FOLDER_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CREATE_SHEET_REQUEST:
            return { ...state, loading: true };
        case CREATE_SHEET_SUCCESS:
            return { ...state, loading: false, success: true, newSheet: action.payload };
        case CREATE_SHEET_FAIL:
            return { ...state, loading: false, error: action.payload };
        case UPDATE_FOLDER_REQUEST:
            return { ...state, loading: true };
        case UPDATE_FOLDER_SUCCESS:
            return { ...state, loading: false, success: true, updatedFolder: action.payload };
        case UPDATE_FOLDER_FAIL:
            return { ...state, loading: false, error: action.payload };
        case SEARCH_FOLDERS_REQUEST:
            return { ...state, loading: true };
        case SEARCH_FOLDERS_SUCCESS:
            return { ...state, loading: false, folders: action.payload };
        case SEARCH_FOLDERS_FAIL:
            return { ...state, loading: false, error: action.payload };
        case 'CLEAN_FOLDER_MESSAGES':
            return { ...state, loading: false, success: false, error: "" };
        case "SELECTED_FOLDER":
            return { ...state, loading: false, selectedFolder: action.payload };
        default:
            return state;
    }
};

export const eventsReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_EVENT_REQUEST:
            return { ...state, loading: true };
        case CREATE_EVENT_SUCCESS:
            return { ...state, loading: false, success: true, newEvent: action.payload };
        case CREATE_EVENT_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_EVENTS_BY_PROFESSIONAL_REQUEST:
            return { ...state, loading: true };
        case FIND_EVENTS_BY_PROFESSIONAL_SUCCESS:
            return { ...state, loading: false, events: action.payload };
        case FIND_EVENTS_BY_PROFESSIONAL_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_EVENTS_BY_CLINICAL_REQUEST:
            return { ...state, loading: true };
        case FIND_EVENTS_BY_CLINICAL_SUCCESS:
            return { ...state, loading: false, events: action.payload };
        case FIND_EVENTS_BY_CLINICAL_FAIL:
            return { ...state, loading: false, error: action.payload };
        case 'CLEAN_EVENT_MESSAGES':
            return { ...state, loading: false, success: false, error: "" };
        default:
            return state;
    }
};

export const professionalsReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_PROFESSIONAL_REQUEST:
            return { ...state, loading: true };
        case CREATE_PROFESSIONAL_SUCCESS:
            return { ...state, loading: false, success: true, newProfessional: action.payload };
        case CREATE_PROFESSIONAL_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_PROFESSIONAL_BY_CLINIC_REQUEST:
            return { ...state, loading: true };
        case FIND_PROFESSIONAL_BY_CLINIC_SUCCESS:
            return { ...state, loading: false, professionals: action.payload.professionals, ProfessionalTotalPages: action.payload.totalPages };
        case FIND_PROFESSIONAL_BY_CLINIC_FAIL:
            return { ...state, loading: false, error: action.payload };
        case UPDATE_USER_PASSWORD_REQUEST:
            return { ...state, loading: true };
        case UPDATE_USER_PASSWORD_SUCCESS:
            return { ...state, loading: false, success: true, updatedProfessional: action.payload };
        case UPDATE_USER_PASSWORD_FAIL:
            return { ...state, loading: false, error: action.payload };
        case GET_ALL_PROFESSIONALS_REQUEST:
            return { ...state, loading: true };
        case GET_ALL_PROFESSIONALS_SUCCESS:
            return { ...state, loading: false, professionals: action.payload.professionals, ProfessionalTotalPages: action.payload.totalPages };
        case GET_ALL_PROFESSIONALS_FAIL:
            return { ...state, loading: false, error: action.payload };
        case UPDATE_PROFESSIONAL_REQUEST:
            return { ...state, loading: true };
        case UPDATE_PROFESSIONAL_SUCCESS:
            return { ...state, loading: false, success: true, updatedProfessional: action.payload };
        case UPDATE_PROFESSIONAL_FAIL:
            return { ...state, loading: false, error: action.payload };
        case 'CLEAN_PROFESSIONAL_MESSAGES':
            return { ...state, loading: false, success: false, error: "" };
        default:
            return state;
    }
};

export const clinicsReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CLINIC_REQUEST:
            return { ...state, loading: true };
        case CREATE_CLINIC_SUCCESS:
            return { ...state, loading: false, success: true, newClinic: action.payload };
        case CREATE_CLINIC_FAIL:
            return { ...state, loading: false, success: false, error: action.payload };
        case FIND_ALL_CLINICS_REQUEST:
            return { ...state, loading: true };
        case FIND_ALL_CLINICS_SUCCESS:
            return { ...state, loading: false, clinics: action.payload };
        case FIND_ALL_CLINICS_FAIL:
            return { ...state, loading: false, error: action.payload };
        case UPDATE_CLINIC_REQUEST:
            return { ...state, loading: true };
        case UPDATE_CLINIC_SUCCESS:
            return { ...state, loading: false, success: true, updatedClinic: action.payload };
        case UPDATE_CLINIC_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_CLINICAL_BY_ID_REQUEST:
            return { ...state, loading: true };
        case FIND_CLINICAL_BY_ID_SUCCESS:
            return { ...state, loading: false, clinical: action.payload };
        case FIND_CLINICAL_BY_ID_FAIL:
            return { ...state, loading: false, error: action.payload };
        case 'CLEAN_CLINIC_MESSAGES':
            return { ...state, loading: false, success: false, error: "" };
        case 'SELECTED_CLINIC':
            return { ...state, loading: false, selectedClinic: action.payload };
        default:
            return state;
    }
};

export const billsReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_BILL_REQUEST:
            return { ...state, loading: true };
        case CREATE_BILL_SUCCESS:
            return { ...state, loading: false, success: true, newBill: action.payload };
        case CREATE_BILL_FAIL:
            return { ...state, loading: false, error: action.payload };
        case FIND_BILL_BY_CLINIC_REQUEST:
            return { ...state, loading: true };
        case FIND_BILL_BY_CLINIC_SUCCESS:
            return { ...state, loading: false, bills: action.payload };
        case FIND_BILL_BY_CLINIC_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};