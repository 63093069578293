import React from 'react'
import './widget.css'

const Widget = ({ title, count }) => {


    return (
        <div className="widget">
            <div className="left">
                <span className="title">{title.toUpperCase()}</span>
                <span className="counter">{count}</span>

            </div>

        </div>
    )
}

export default Widget